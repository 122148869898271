import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAll as apiGetAll,
  getOne as apiGetOne,
  insert as apiInsert,
  update as apiUpdate,
  destroy as apiDestroy,
} from "../../services/productApi";
import { IProduct, IProductInput } from "../../services/productApi/types";
import { IPaginatedData, ISimpleQueryParams } from "../../interfaces/global";

export const getAll = createAsyncThunk(
  "product/getAll",
  async (query?: ISimpleQueryParams) => {
    const paginatedData = await apiGetAll(query);
    return paginatedData;
  }
);

export const getOne = createAsyncThunk("product/getOne", async (id: string) => {
  const data = await apiGetOne(id);
  return data;
});

export const insert = createAsyncThunk(
  "product/insert",
  async (input: IProductInput, { rejectWithValue }) => {
    try {
      await apiInsert(input);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const update = createAsyncThunk(
  "product/update",
  async (
    { id, input }: { id: string; input: IProductInput },
    { rejectWithValue }
  ) => {
    try {
      await apiUpdate(id, input);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const destroy = createAsyncThunk(
  "product/destroy",
  async (id: string) => {
    await apiDestroy(id);
    return true;
  }
);

interface ProductSliceState {
  isLoadingDatas: boolean;
  paginatedData: IPaginatedData<IProduct> | null;
  isLoadingData: boolean;
  data?: IProduct;
  isLoadingSave: boolean;
  isLoadingDestroy: boolean;
}

const initialState: ProductSliceState = {
  isLoadingDatas: false,
  paginatedData: null,
  isLoadingData: false,
  data: undefined,
  isLoadingSave: false,
  isLoadingDestroy: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setIsLoadingSave(state, action) {
      state.isLoadingSave = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.paginatedData = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getOne.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(getOne.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(insert.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(insert.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(insert.rejected, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(update.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(update.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(destroy.pending, (state) => {
      state.isLoadingDestroy = true;
    });

    builder.addCase(destroy.fulfilled, (state) => {
      state.isLoadingDestroy = false;
    });
  },
});

export const { setIsLoadingSave } = productSlice.actions;

export default productSlice.reducer;
