import React, { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { FormMultiCheckboxProps } from "./types";

const FormMultiCheckbox: React.FC<FormMultiCheckboxProps> = ({
  options,
  values,
  onChange,
}) => {
  const [listChecked, setListChecked] = useState<string[]>(values || []);

  const onLocalChange = (e: any) => {
    const newListChecked = [...listChecked];
    const checked = e.target.checked;
    const value = e.target.value;
    const checkedIndex = newListChecked.indexOf(value);
    if (checked && checkedIndex < 0) {
      newListChecked.push(value);
    } else {
      newListChecked.splice(checkedIndex, 1);
    }
    setListChecked(newListChecked);
    if (onChange) {
      onChange(newListChecked);
    }
  };

  return (
    <div className="multi-checkbox">
      {options.map((option) => (
        <FormGroup check key={option.value}>
          <Label check>
            <Input
              type="checkbox"
              value={option.value}
              checked={listChecked.includes(option.value)}
              onChange={onLocalChange}
            />{" "}
            {option.label}
          </Label>
        </FormGroup>
      ))}
    </div>
  );
};

export default FormMultiCheckbox;
