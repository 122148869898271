import axios from "axios";
import { IAccountInput } from "./types";

const data = {
  firstName: "Ary",
  lastName: "Analog",
  email: "ary@analogrepublic.com",
};

export const get = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 1000);
  });
};

export const save = async (data: IAccountInput) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ENPOINT}/admin/category`
  );
};
