import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAll as apiGetAll } from "../../services/adminApi";
import { IAdminData } from "../../services/adminApi/types";

export const getAll = createAsyncThunk("admin/getAll", async () => {
  const data = await apiGetAll();
  return data;
});

interface AdminSliceState {
  isLoadingDatas: boolean;
  datas: IAdminData[];
}

const initialState: AdminSliceState = {
  isLoadingDatas: false,
  datas: [],
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.datas = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getAll.rejected, (state, action) => {
      state.isLoadingDatas = false;
    });
  },
});

export default adminSlice.reducer;
