import axios from "axios";
import { ILoginInput } from "./types";

export const login = async (data: ILoginInput) => {
  return await axios.post(`${process.env.REACT_APP_API_ENPOINT}/login`, data);
};

export const logout = async () => {
  return await axios.post(`${process.env.REACT_APP_API_ENPOINT}/logout`);
};
