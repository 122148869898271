// String.prototype.rtrim = function () {
// return this.replace(/((\s*\S+)*)\s*/, '$1');
// };

// String.prototype.ltrim = function () {
// return this.replace(/\s*((\S+\s*)*)/, '$1');
// };

export const textEllipsis = (
  text: string,
  firstDigit: number = 10,
  lastDigit: number = 0
) => {
  const firstText = text.substring(0, firstDigit);
  const lastText = text.substring(text.length - lastDigit);

  if (!text) {
    return "";
  }

  if (text.length > firstText.length + lastText.length) {
    // return `${firstText.rtrim()}...${lastText.ltrim()}`;
    return `${firstText}...${lastText}`;
  } else {
    return text;
  }
};

export function replaceRefToInneRef(obj: any) {
  const oldRef = obj.ref;
  delete obj.ref;
  return {
    ...obj,
    innerRef: oldRef,
  };
}

export const getImage = (path: string) => {
  return `${process.env.REACT_APP_MEDIA_URL}/${path}`;
};
