import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";
import { SimpleHeaderProps } from "./types";

const SimpleHeader: React.FC<SimpleHeaderProps> = ({
  name,
  parents,
  rightElements,
}) => {
  return (
    <>
      <div className="header header-dark bg-gradient-black pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  {name}
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <Link to="/admin">
                      <i className="fas fa-home" />
                    </Link>
                  </BreadcrumbItem>
                  {parents &&
                    parents.map((parent) => (
                      <BreadcrumbItem key={`${parent.label}`}>
                        <Link to={parent.to} title={parent.title}>
                          {parent.label}
                        </Link>
                      </BreadcrumbItem>
                    ))}
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              {rightElements && (
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  {rightElements}
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SimpleHeader;
