import React, { useCallback, useEffect, useState, LegacyRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Button,
  UncontrolledTooltip,
  FormGroup,
  Table,
} from "reactstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import RowItem from "../../../components/SortableRow/RowItem";
import classnames from "classnames";
import useConfirmation from "../../../hooks/useConfirmation";
import { ProductOptionSizeProps, IFormProductSizeWithKey } from "../types";

const ProductOptionSize: React.FC<ProductOptionSizeProps> = ({
  onChange,
  values,
  error,
}) => {
  const [nameValue, setNameValue] = useState("");

  const [valueNotAllowed, setValueNotAllowed] = useState(false);

  const [productSizes, setProductSizes] = useState<IFormProductSizeWithKey[]>(
    []
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  const checkValue = (name: string) => {
    if (
      name &&
      productSizes.findIndex(
        (elm) => elm.name.toLowerCase() === name.toLowerCase()
      ) >= 0
    ) {
      setValueNotAllowed(true);
    } else {
      setValueNotAllowed(false);
    }
  };

  useEffect(() => {
    setProductSizes(
      values.map((value, index) => {
        return {
          ...value,
          currentIndex: index,
        };
      }) || []
    );
    setCurrentIndex(values.length);
  }, [values]);

  const { showConfirmation, hideConfirmation } = useConfirmation();

  const insertProductSize = () => {
    const newProductSizes = [
      ...productSizes,
      {
        currentIndex,
        name: nameValue,
      },
    ];

    setCurrentIndex(currentIndex + 1);

    setNameValue("");
    setProductSizes(newProductSizes);
    onChange(newProductSizes);
  };

  const moveCard: (dragIndex: number, hoverIndex: number) => void = useCallback(
    (dragIndex, hoverIndex) => {
      const newProductSizes = update(productSizes, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, productSizes[dragIndex]],
        ],
      });
      setProductSizes(newProductSizes);
      onChange(newProductSizes);
    },
    [productSizes, onChange]
  );

  const renderItem = useCallback(
    (productSize: IFormProductSizeWithKey, index: number) => {
      const onDelete = (index: number) => {
        const newProductSizes = [...productSizes];
        newProductSizes.splice(index, 1);

        setProductSizes(newProductSizes);
        onChange(newProductSizes);

        hideConfirmation();
      };

      return (
        <RowItem
          id={productSize.currentIndex.toString()}
          key={productSize.currentIndex}
          index={index}
          moveCard={moveCard}
        >
          {({ dragRef, previewRef, handlerId, isDragging }) => (
            <tr
              ref={previewRef as LegacyRef<HTMLTableRowElement>}
              data-handler-id={handlerId}
              className={classnames("sortable-row")}
              style={{ opacity: isDragging ? 0 : 1 }}
            >
              <td>
                <div
                  ref={dragRef}
                  className={classnames("sortable-row__pointer", {
                    "sortable-row__pointer--dragging": isDragging,
                  })}
                >
                  <i className="fas fa-sort"></i>
                </div>
              </td>
              <td>{productSize.name}</td>
              <td className="table-actions">
                <Button
                  color="link"
                  className="table-action table-action-delete"
                  id={`tooltip-delete-${productSize.currentIndex}`}
                  onClick={(e) => {
                    e.preventDefault();

                    showConfirmation({
                      title: "Are you sure want to delete?",
                      onConfirm: () => onDelete(index),
                    });
                  }}
                >
                  <i className="fas fa-trash" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`tooltip-delete-${productSize.currentIndex}`}
                >
                  Delete Product Size
                </UncontrolledTooltip>
              </td>
            </tr>
          )}
        </RowItem>
      );
    },
    [moveCard, productSizes, showConfirmation, hideConfirmation, onChange]
  );

  return (
    <Row>
      <Col>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h3 className="mb-0 text-brand">
                    Product Sizes
                    {error ? (
                      <span className="d-inline-block ml-2 invalid-feedback font-weight-normal w-auto">
                        - {error}
                      </span>
                    ) : (
                      ""
                    )}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup className="row mb-0">
                <Col md="7">
                  <Input
                    placeholder="Product size name"
                    type="text"
                    value={nameValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      setNameValue(value);
                      checkValue(value);
                    }}
                  />
                </Col>
                <Col md="5">
                  <Button
                    type="button"
                    color="neutral"
                    onClick={() => {
                      insertProductSize();
                    }}
                    disabled={nameValue === "" || valueNotAllowed}
                  >
                    Add Size
                  </Button>
                </Col>
              </FormGroup>
              {productSizes.length >= 1 && (
                <Table
                  className="align-items-center table-flush mt-4"
                  responsive
                  striped
                >
                  <thead className="thead-light">
                    <tr>
                      <th />
                      <th>SIze</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <DndProvider backend={HTML5Backend}>
                      {productSizes.map((productSize, index) =>
                        renderItem(productSize, index)
                      )}
                    </DndProvider>
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default ProductOptionSize;
