import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Link } from "react-router-dom";
import useConfirmation from "../../hooks/useConfirmation";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import usePagination from "../../hooks/usePagination";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  destroy,
  setStatus,
  updateStatus,
} from "../../redux/reducers/customer";
import { getAll } from "../../redux/reducers/customer";
import { statusFromBEMap } from "../../constants/global";
import ModalChangeStatus, { ModalChangeStatusProps } from "./ModalChangeStatus";
import { ICustomerData } from "../../services/customerApi/types";

const perPage = 10;
const Customer: React.FC = (props) => {
  const { pageNumber, goToPage } = usePagination({ props });

  const dispatch = useAppDispatch();

  const { isLoadingDatas, datas, isLoadingDestroy, isLoadingSave } =
    useAppSelector((state) => state.customer);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  useEffect(() => {
    dispatch(getAll());
  }, []);

  const { showConfirmation, hideConfirmation } =
    useConfirmation(isLoadingDestroy);

  const onDelete = async (id: string) => {
    try {
      await dispatch(destroy(id));

      showNotificationAlert({
        title: "Success",
        message: "You have deleted the customer successfully",
      });

      await dispatch(getAll());
    } catch (err: any) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: "danger",
            title: "Failed",
            message: err.message,
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  const [modalChangeStatus, setModalChangeStatus] =
    useState<ModalChangeStatusProps>({
      isShow: false,
      onSubmit: () => {},
      onClose: () => {},
    });

  const onStatusChange = (data: ICustomerData) => {
    setModalChangeStatus((localData) => ({
      ...localData,
      isShow: true,
      customerData: data,
      onSubmit: async ({ status }) => {
        try {
          await dispatch(
            updateStatus({ id: data.id, status: status || "active" })
          );
          setModalChangeStatus(() => ({
            ...modalChangeStatus,
            isShow: false,
          }));
          dispatch(setStatus({ id: data.id, status }));
        } catch (err) {
          console.log("__err", err);
        }
      },
    }));
  };

  return (
    <>
      <ModalChangeStatus
        {...modalChangeStatus}
        isLoadingConfirm={isLoadingSave}
        onClose={() =>
          setModalChangeStatus((localData) => ({ ...localData, isShow: false }))
        }
      />
      <SimpleHeader name="Customer" rightElements={<></>} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">List Customers</h3>
                  </Col>
                </Row>
              </CardHeader>

              {isLoadingDatas && (
                <CardBody>
                  <p className="text-center">Loading...</p>
                </CardBody>
              )}

              {!isLoadingDatas && datas.length <= 0 && (
                <CardBody>
                  <p className="text-center">There is no data!</p>
                </CardBody>
              )}

              {!isLoadingDatas && datas.length >= 1 && (
                <>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    striped
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Full Name</th>
                        <th>Company Name</th>
                        <th>Status</th>
                        <th>Manage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datas.map((data) => (
                        <tr key={`data-${data.id}`}>
                          <td>{`${data.first_name} ${data.last_name}`}</td>
                          <td>{data.company_name}</td>
                          <td>
                            {/*<h4>
                              <Badge
                                color={
                                  data.status === "active"
                                    ? "success"
                                    : "danger"
                                }
                                onClick={() => onStatusChange(data)}
                              >
                                {statusFromBEMap[data.status]}
                              </Badge>
                              </h4>*/}
                          </td>
                          <td className="table-actions">
                            <div className="d-flex">
                              <Link
                                className="table-action"
                                to={`/admin/customer/${data.id}/edit`}
                                id={`tooltip-edit-${data.id}`}
                              >
                                <i className="fas fa-user-edit" />
                              </Link>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltip-edit-${data.id}`}
                              >
                                Edit Customer
                              </UncontrolledTooltip>

                              <button
                                className="table-action table-action-delete"
                                id={`tooltip-delete-${data.id}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  showConfirmation({
                                    title: "Are you sure want to delete?",
                                    onConfirm: () => onDelete(data.id),
                                  });
                                }}
                              >
                                <i className="fas fa-trash" />
                              </button>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltip-delete-${data.id}`}
                              >
                                Delete Customer
                              </UncontrolledTooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    {/*<Pagination
                      totalRow={data.getPresetDataSets?.paginatorInfo.total}
                      perPage={perPage}
                      initialPage={pageNumber - 1}
                      onPageChange={(data) => {
                        goToPage(data);
                      }}
                    />
                    */}
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Customer;
