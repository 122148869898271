import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import useConfirmation from "../../hooks/useConfirmation";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import usePagination from "../../hooks/usePagination";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { destroy, getAll } from "../../redux/reducers/media";
import { getImage } from "../../helpers/global";

const Image: React.FC = (props) => {
  const { pageNumber, goToPage } = usePagination({ props });

  const dispatch = useAppDispatch();

  const { isLoadingDatas, paginatedData, isLoadingDestroy } = useAppSelector(
    (state) => state.media
  );

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  useEffect(() => {
    dispatch(getAll({ page: pageNumber }));
  }, [pageNumber]);

  const imageLists = useMemo(() => {
    return (paginatedData?.data || []).map((elm) => ({
      ...elm,
      imageUrl: elm.image ? getImage(elm.image) : "",
    }));
  }, [paginatedData]);

  const { showConfirmation, hideConfirmation } =
    useConfirmation(isLoadingDestroy);

  const onDelete = async (id: string) => {
    try {
      await dispatch(destroy(id));

      showNotificationAlert({
        title: "Success",
        message: "You have deleted the image successfully",
      });
      dispatch(getAll({ page: pageNumber }));
    } catch (err: any) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: "danger",
            title: "Failed",
            message: err.message,
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  return (
    <>
      <SimpleHeader name="Image" rightElements={<></>} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">List Images</h3>
                  </Col>
                </Row>
              </CardHeader>

              {isLoadingDatas && (
                <CardBody>
                  <p className="text-center">Loading...</p>
                </CardBody>
              )}

              {!isLoadingDatas && !imageLists?.length && (
                <CardBody>
                  <p className="text-center">There is no data!</p>
                </CardBody>
              )}

              {!isLoadingDatas && imageLists?.length && (
                <>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    striped
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Image</th>
                        <th>Path</th>
                        <th>Attached to</th>
                        <th>Manage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {imageLists.map((data) => (
                        <tr key={`data-${data.id}`}>
                          <td>
                            {data.imageUrl ? (
                              <img
                                src={data.imageUrl}
                                style={{ maxWidth: "100px" }}
                              />
                            ) : null}
                          </td>
                          <td>{data.image}</td>
                          <td>{data.attached_to}</td>
                          <td className="table-actions">
                            <div className="d-flex">
                              <button
                                className="table-action table-action-delete"
                                id={`tooltip-delete-${data.id}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  showConfirmation({
                                    title: "Are you sure want to delete?",
                                    onConfirm: () => onDelete(data.id),
                                  });
                                }}
                              >
                                <i className="fas fa-trash" />
                              </button>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltip-delete-${data.id}`}
                              >
                                Delete Image
                              </UncontrolledTooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <Pagination
                      totalRow={paginatedData?.total}
                      perPage={paginatedData?.per_page}
                      initialPage={pageNumber - 1}
                      onPageChange={(data: any) => {
                        goToPage(data);
                      }}
                    />
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Image;
