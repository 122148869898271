import React from "react";
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import { SidebarProps } from "./types";
import { IAccessLevel, IRoute } from "../../interfaces/global";
import { useAppSelector } from "../../redux/store";

const Sidebar: React.FC<SidebarProps> = ({
  toggleSidenav,
  sidenavOpen,
  routes,
  logo,
  rtlActive,
}) => {
  const { me } = useAppSelector((state) => state.user);

  const [state, setState] = React.useState<{ [key: string]: boolean }>({});

  const location = useLocation();
  React.useEffect(() => {
    setState(getCollapseStates(routes));
    // eslint-disable-next-line
  }, []);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes: IRoute[]) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse && prop.state) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop?.views as IRoute[]),
          ...getCollapseStates(prop?.views as IRoute[]),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes: IRoute[]) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        routes[i].collapse
        // && getCollapseInitialState(routes[i].views)
      ) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: IRoute[]) => {
    return routes.map((prop, key) => {
      /*
      if (prop.redirect) {
        return null;
      }
      */

      if (prop.collapse) {
        var st: { [key: string]: boolean } = {};
        st[prop.state as string] = !state[prop.state as string];
        return (
          <NavItem key={key}>
            <NavLink
              href="#"
              data-toggle="collapse"
              aria-expanded={state[prop.state as string]}
              className={classnames({
                active: getCollapseInitialState(prop.views as IRoute[]),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon">{prop.miniName}</span>
                  <span className="sidenav-normal">{prop.name}</span>
                </>
              ) : null}
            </NavLink>
            {prop.views && (
              <Collapse
                isOpen={prop.state !== undefined ? state[prop.state] : false}
              >
                <Nav className="nav-sm flex-column">
                  {createLinks(prop.views)}
                </Nav>
              </Collapse>
            )}
          </NavItem>
        );
      }

      if (prop.hideFromSidebar) {
        return null;
      }

      return (
        <NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        <div
          className={classnames("sidenav-toggler d-none d-xl-block", {
            active: sidenavOpen,
          })}
          onClick={toggleSidenav}
        >
          <div className="sidenav-toggler-inner">
            <i className="sidenav-toggler-line" />
            <i className="sidenav-toggler-line" />
            <i className="sidenav-toggler-line" />
          </div>
        </div>

        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>
            {createLinks(
              routes.filter((elm) =>
                elm.accessLevels.includes(me?.role as IAccessLevel)
              )
            )}
          </Nav>
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
        (rtlActive ? "" : "fixed-left")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

export default Sidebar;
