import { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  FormFeedback,
} from "reactstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";
import { Link } from "react-router-dom";
import { FormProductProps, IFormProductVariant } from "./types";
import Toggle from "../../components/Toggle";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  fetchParentLists,
  getAll as getAllCategories,
} from "../../redux/reducers/category";
import ProductImages from "./ProductImages";
import UppyUploader from "../../components/UppyUploader";
import FormMultiCheckbox from "../../components/forms/FormMultiCheckbox";
import slugify from "slugify";
import { slugPattern } from "../../constants/global";
import ProductOptionColor from "./productOption/Color";
import ProductOptionSize from "./productOption/Size";
import ProductVariant from "./ProductVariant";

const FormProduct: React.FC<FormProductProps> = ({
  onSubmit,
  isLoadingSave,
  coverImage,
  coverSquareImage,
  defaultValues,
  type,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const watchOptionsColors = watch("options.colors");
  const watchOptionsSizes = watch("options.sizes");

  useEffect(() => {
    const currentVariants = getValues("variants");

    const data: IFormProductVariant[] = [];

    if (watchOptionsColors) {
      for (let i = 0; i < watchOptionsColors.length; i++) {
        const watchOptionsColor = watchOptionsColors[i];

        if (watchOptionsSizes) {
          for (let n = 0; n < watchOptionsSizes.length; n++) {
            const watchOptionsSize = watchOptionsSizes[n];

            const currentVariant = currentVariants?.find(
              (elm) =>
                elm.colorIndex === watchOptionsColor.currentIndex &&
                elm.sizeIndex === watchOptionsSize.currentIndex
            );

            data.push({
              ...currentVariant,
              colorIndex: i,
              sizeIndex: n,
              sku: currentVariant?.sku || "",
              price: currentVariant?.price || "",
            });
          }
        } else {
          const currentVariant = currentVariants?.find(
            (elm) => elm.colorIndex === watchOptionsColor.currentIndex
          );

          data.push({
            colorIndex: i,
            sku: currentVariant?.sku || "",
            price: currentVariant?.price || "",
          });
        }
      }
    }
    setValue("variants", data);
  }, [watchOptionsColors, watchOptionsSizes, setValue, getValues]);

  const dispatch = useAppDispatch();

  const { isLoadingDatas: isLoadingCategories, parentLists } = useAppSelector(
    (state) => state.category
  );

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(fetchParentLists({ per_page: 100 }));
  }, [dispatch]);

  const watchName = watch("productName");

  useEffect(() => {
    if (!dirtyFields.hasOwnProperty("slug") && watchName && type === "add") {
      setValue("slug", slugify(watchName, { lower: true }));
    }
  }, [watchName, dirtyFields, setValue, type]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Product Details</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="productCode"
                      md="3"
                    >
                      Product Code
                    </Label>
                    <Col md="9">
                      <Input
                        id="productCode"
                        type="text"
                        {...replaceRefToInneRef(
                          register("productCode", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("productCode")}
                      />
                      {errors.hasOwnProperty("productCode") && (
                        <FormFeedback>Product Code is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="productName"
                      md="3"
                    >
                      Product Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="productName"
                        type="text"
                        {...replaceRefToInneRef(
                          register("productName", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("productName")}
                      />
                      {errors.hasOwnProperty("productName") && (
                        <FormFeedback>Product Name is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="form-control-label" htmlFor="name" md="3">
                      Slug
                    </Label>
                    <Col md="9">
                      <Input
                        id="slug"
                        type="text"
                        {...replaceRefToInneRef(
                          register("slug", {
                            required: true,
                            pattern: slugPattern,
                          })
                        )}
                        invalid={errors.hasOwnProperty("slug")}
                      />
                      {errors.hasOwnProperty("slug") && (
                        <>
                          {errors.slug?.type === "required" && (
                            <FormFeedback>Slug is required!</FormFeedback>
                          )}
                          {errors.slug?.type === "pattern" && (
                            <FormFeedback>Wrong slug!</FormFeedback>
                          )}
                        </>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="3"
                    >
                      Cover Image
                    </Label>
                    <Col md="9">
                      <Controller
                        control={control}
                        name="coverImage"
                        render={({ field: { onChange, value } }) => (
                          <UppyUploader
                            currentImage={coverImage}
                            title="Choose Image"
                            onChange={(imageId: string) => {
                              onChange(imageId);
                            }}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="3"
                    >
                      Cover Square Image
                    </Label>
                    <Col md="9">
                      <Controller
                        control={control}
                        name="coverSquareImage"
                        render={({ field: { onChange, value } }) => (
                          <UppyUploader
                            currentImage={coverSquareImage}
                            title="Choose Image"
                            onChange={(imageId: string) => {
                              onChange(imageId);
                            }}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="category"
                      md="3"
                    >
                      Product Category
                    </Label>
                    <Col md="9">
                      {isLoadingCategories ? (
                        <p>Loading...</p>
                      ) : (
                        <Controller
                          control={control}
                          name="categories"
                          render={({ field: { onChange, value } }) => {
                            const values = value?.map((v) =>
                              v.categoryId.toString()
                            );

                            return (
                              <FormMultiCheckbox
                                options={(parentLists?.data || []).map(
                                  (elm) => ({
                                    value: elm.id.toString(),
                                    label: elm.name,
                                  })
                                )}
                                values={values}
                                onChange={(values) => {
                                  const newValues =
                                    values?.map((data) => {
                                      const existingData = value?.find(
                                        (va) =>
                                          va.categoryId.toString() ===
                                          data.toString()
                                      );

                                      if (existingData) {
                                        return existingData;
                                      }

                                      return { categoryId: parseInt(data) };
                                    }) || [];

                                  onChange(newValues);
                                }}
                              />
                            );
                          }}
                        />
                      )}
                      {errors.hasOwnProperty("category") && (
                        <FormFeedback>
                          Product Category is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="description"
                      md="3"
                    >
                      Description
                    </Label>
                    <Col md="9">
                      <Input
                        id="description"
                        type="textarea"
                        {...replaceRefToInneRef(
                          register("description", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("description")}
                      />
                      {errors.hasOwnProperty("description") && (
                        <FormFeedback>Description is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="status"
                      md="3"
                    >
                      Status Available
                    </Label>
                    <Col md="9">
                      <Controller
                        control={control}
                        name="status"
                        defaultValue={true}
                        render={({ field: { onChange, value } }) => (
                          <Toggle active={value} onChange={onChange} />
                        )}
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Controller
          control={control}
          name={`productImages`}
          rules={{
            validate: (val) => {
              if (val === undefined) {
                return "You need at least 1 image!";
              }
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <ProductImages
                  onChange={(value) => {
                    onChange(value);
                  }}
                  values={value}
                  error={error?.type === "validate" ? error.message : undefined}
                />
              </>
            );
          }}
        />

        <Controller
          control={control}
          name={`options.colors`}
          rules={{
            validate: (val) => {
              for (let i = 0; i < val.length; i++) {
                if (val[i].name === "" || val[i].image === "") {
                  return "Please complete all product colors!";
                }
              }
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ProductOptionColor
              onChange={onChange}
              values={value}
              error={error?.type === "validate" ? error.message : undefined}
            />
          )}
        />

        <Controller
          control={control}
          name={`options.sizes`}
          rules={{
            validate: (val) => {
              if (val) {
                for (let i = 0; i < val.length; i++) {
                  if (val[i].name === "") {
                    return "Please complete all product sizes!";
                  }
                }
              }
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ProductOptionSize
              onChange={onChange}
              values={value || []}
              error={error?.type === "validate" ? error.message : undefined}
            />
          )}
        />

        <Controller
          control={control}
          name={`variants`}
          rules={{
            validate: (val) => {
              if (val) {
                for (let i = 0; i < val.length; i++) {
                  if (val[i].sku === "") {
                    return "Please complete all product variants!";
                  }
                }
              }
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ProductVariant
              colors={watchOptionsColors}
              sizes={watchOptionsSizes}
              onChange={onChange}
              values={value || []}
              error={error?.type === "validate" ? error.message : undefined}
            />
          )}
        />

        <Card>
          <CardBody>
            <Button type="submit" color="primary" disabled={isLoadingSave}>
              {isLoadingSave && <span>Loading...</span>}
              {!isLoadingSave && <span>Save</span>}
            </Button>
            <Link className="btn btn-secondary" to="/admin/product">
              Cancel
            </Link>
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default FormProduct;
