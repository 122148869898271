import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  FormFeedback,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";
import { Link } from "react-router-dom";
import { FormUserProps } from "./types";
import FormPassword from "../../components/forms/FormPassword";
import { passwordPattern } from "../../constants/global";
import { useEffect } from "react";

const FormUser: React.FC<FormUserProps> = ({
  onSubmit,
  isLoadingSave,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,
    trigger,
    control,
    setValue,
  } = useForm({
    defaultValues,
  });

  const watchPassword = watch("password");

  useEffect(() => {
    if (isSubmitted) {
      trigger("confirmPassword");
    }
  }, [watchPassword, isSubmitted]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">User Details</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="firstName"
                      md="3"
                    >
                      First Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="firstName"
                        type="text"
                        {...replaceRefToInneRef(
                          register("firstName", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("firstName")}
                      />
                      {errors.hasOwnProperty("firstName") && (
                        <FormFeedback>First Name is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="lastName"
                      md="3"
                    >
                      Last Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="lastName"
                        type="text"
                        {...replaceRefToInneRef(
                          register("lastName", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("lastName")}
                      />
                      {errors.hasOwnProperty("lastName") && (
                        <FormFeedback>Last Name is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="email"
                      md="3"
                    >
                      Email
                    </Label>
                    <Col md="9">
                      <Input
                        id="email"
                        type="text"
                        {...replaceRefToInneRef(
                          register("email", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("email")}
                      />
                      {errors.hasOwnProperty("email") && (
                        <FormFeedback>Email is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="password"
                      md="3"
                    >
                      Password
                    </Label>
                    <Col md="9">
                      <FormPassword
                        {...register("password", {
                          minLength: 8,
                          pattern: passwordPattern,
                        })}
                        placeholder="Password"
                        id="password"
                        invalid={errors.hasOwnProperty("password")}
                        feedbackElements={
                          <>
                            {errors?.password?.type === "minLength" && (
                              <FormFeedback>
                                The minimum password length is 8!
                              </FormFeedback>
                            )}
                            {errors?.password?.type === "pattern" && (
                              <FormFeedback>
                                The password should contain at least 1
                                lowercase, 1 uppercase, 1 special character, and
                                1 number.
                              </FormFeedback>
                            )}
                          </>
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="confirmPassword"
                      md="3"
                    >
                      Confirm Password
                    </Label>
                    <Col md="9">
                      <FormPassword
                        {...register("confirmPassword", {
                          minLength: 8,
                          pattern: passwordPattern,
                          validate: {
                            required: (val) => {
                              if (watchPassword && val === "") {
                                return "Confirm Password is required!";
                              }
                            },
                            passwordNotMatch: (val) => {
                              if (watchPassword !== val) {
                                return "The password fields do not match!";
                              }
                            },
                          },
                        })}
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        invalid={errors.hasOwnProperty("confirmPassword")}
                        feedbackElements={
                          <>
                            {errors?.confirmPassword?.type === "minLength" && (
                              <FormFeedback>
                                The minimum password length is 8!
                              </FormFeedback>
                            )}
                            {errors?.confirmPassword?.type === "pattern" && (
                              <FormFeedback>
                                The password should contain at least 1
                                lowercase, 1 uppercase, 1 special character, and
                                1 number.
                              </FormFeedback>
                            )}
                            {(errors?.confirmPassword?.type === "required" ||
                              errors?.confirmPassword?.type ===
                                "passwordNotMatch") && (
                              <FormFeedback>
                                {errors.confirmPassword.message}
                              </FormFeedback>
                            )}
                          </>
                        }
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Button type="submit" color="primary" disabled={isLoadingSave}>
              {isLoadingSave && <span>Loading...</span>}
              {!isLoadingSave && <span>Save</span>}
            </Button>
            <Link className="btn btn-secondary" to="/admin/user">
              Cancel
            </Link>
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default FormUser;
