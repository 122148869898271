import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAll as apiGetAll,
  getOne as apiGetOne,
  insert as apiInsert,
  update as apiUpdate,
  updateStatus as apiUpdateStatus,
  destroy as apiDestroy,
} from "../../services/customerApi";
import {
  ICustomerData,
  ICustomerInput,
  IStatus,
} from "../../services/customerApi/types";

export const getAll = createAsyncThunk("customer/getAll", async () => {
  const { data } = await apiGetAll();
  return data;
});

export const getOne = createAsyncThunk(
  "customer/getOne",
  async (id: string) => {
    const data = await apiGetOne(id);
    return data;
  }
);

export const insert = createAsyncThunk(
  "customer/insert",
  async (input: ICustomerInput, { rejectWithValue }) => {
    try {
      await apiInsert(input);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const update = createAsyncThunk(
  "customer/update",
  async (
    { id, input }: { id: string; input: ICustomerInput },
    { rejectWithValue }
  ) => {
    try {
      await apiUpdate(id, input);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "customer/updateStatus",
  async (
    { id, status }: { id: string; status: IStatus },
    { rejectWithValue }
  ) => {
    try {
      await apiUpdateStatus(id, status);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const destroy = createAsyncThunk(
  "category/destroy",
  async (id: string) => {
    await apiDestroy(id);
    return true;
  }
);

interface CustomerSliceState {
  isLoadingDatas: boolean;
  datas: ICustomerData[];
  isLoadingData: boolean;
  data?: ICustomerData;
  isLoadingSave: boolean;
  isLoadingDestroy: boolean;
}

const initialState: CustomerSliceState = {
  isLoadingDatas: false,
  datas: [],
  isLoadingData: false,
  data: undefined,
  isLoadingSave: false,
  isLoadingDestroy: false,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setStatus(state, action) {
      const index = state.datas.findIndex(
        (elm) => elm.id === action.payload.id
      );
      state.datas[index].status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.datas = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getAll.rejected, (state, action) => {
      state.isLoadingDatas = false;
    });

    builder.addCase(getOne.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(getOne.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(insert.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(insert.rejected, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(insert.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(update.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(update.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(update.rejected, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(updateStatus.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(updateStatus.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(updateStatus.rejected, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(destroy.pending, (state) => {
      state.isLoadingDestroy = true;
    });

    builder.addCase(destroy.fulfilled, (state) => {
      state.isLoadingDestroy = false;
    });
  },
});

export const { setStatus } = customerSlice.actions;

export default customerSlice.reducer;
