import React, { useState, useEffect } from "react";
import FormProduct from "./FormProduct";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Redirect, useParams } from "react-router";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { Link } from "react-router-dom";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import {
  IFormProductColorWithKey,
  IFormProduct,
  IFormProductSizeWithKey,
  IFormProductVariant,
  IProductImage,
  IProductCategory,
} from "./types";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getOne, setIsLoadingSave, update } from "../../redux/reducers/product";
import { getImage } from "../../helpers/global";

const EditProduct = () => {
  const { id }: { id: string } = useParams();

  const { isLoadingSave, isLoadingData, data } = useAppSelector(
    (state) => state.product
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOne(id));
  }, [dispatch, id]);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  const [isRedirect, setIsRedirect] = useState(false);

  const onSubmit = async (data: IFormProduct) => {
    const {
      productCode,
      productName,
      slug,
      coverImage,
      coverSquareImage,
      categories,
      description,
      status,
      productImages,
      options,
      variants,
    } = data;

    try {
      await dispatch(
        update({
          id,
          input: {
            product_code: productCode,
            product_name: productName,
            slug: slug,
            categories: categories.map(({ categoryId, ...rest }) => ({
              ...rest,
              category_id: categoryId,
            })),
            description,
            status_available: status,
            cover_image_id: coverImage.toString(),
            cover_square_image_id: coverSquareImage.toString(),
            product_images: productImages.map((elm) => elm.value),
            options: {
              colors: options?.colors.map(({ name, image }) => ({
                name,
                image_id: image,
              })),
              sizes: options?.sizes?.map(({ name }) => ({
                name,
              })),
            },
            product_variation: variants?.map(
              ({ id, image, sku, colorIndex, sizeIndex, price }) => ({
                id,
                image_id: image,
                sku,
                color_index: colorIndex,
                size_index: sizeIndex,
                price: parseFloat(price),
              })
            ),
          },
        })
      ).unwrap();

      showNotificationAlert({
        title: "Success",
        message: "You have updated the product successfully",
      });

      setIsRedirect(true);
    } catch (err: any) {
      dispatch(setIsLoadingSave(false));

      showNotificationAlert({
        type: "danger",
        title: "Failed",
        message: err,
      });
    }
  };

  if (isRedirect) {
    return <Redirect to="/admin/product" />;
  }

  return (
    <>
      <SimpleHeader
        name="Edit Product"
        parents={[{ to: "/admin/product", label: "Product" }]}
        rightElements={
          <Link
            to="/admin/product"
            className="btn btn-neutral btn-sm"
            color="default"
          >
            Back
          </Link>
        }
      />
      {isLoadingData && (
        <Container className="mt--6" fluid>
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Product Details</h3>
              </CardHeader>
              <CardBody>Loading...</CardBody>
            </Card>
          </div>
        </Container>
      )}
      {!isLoadingData && (
        <FormProduct
          onSubmit={onSubmit}
          isLoadingSave={isLoadingSave}
          coverImage={
            data && data.cover_image ? getImage(data.cover_image.image) : ""
          }
          coverSquareImage={
            data && data.cover_square_image
              ? getImage(data.cover_square_image.image)
              : ""
          }
          defaultValues={{
            productCode: data?.product_code as string,
            productName: data?.product_name as string,
            slug: data?.slug as string,
            categories: data?.product_categories.map(
              ({ category_id, ...rest }) => ({
                ...rest,
                categoryId: category_id,
              })
            ) as IProductCategory[],
            description: data?.description as string,
            status: data?.status_available as boolean,
            options: {
              colors: data
                ? (data.options.colors.map(
                    ({ name, image: { id, image } }, index) => ({
                      currentIndex: index,
                      name,
                      image: id,
                      imageUrl: getImage(image),
                    })
                  ) as IFormProductColorWithKey[])
                : [],
              sizes: data?.options.sizes?.map(({ name }, index) => ({
                currentIndex: index,
                name,
              })) as IFormProductSizeWithKey[],
            },
            variants: data?.variations.map(
              ({ color_index, size_index, image, ...variation }) => {
                return {
                  ...variation,
                  colorIndex: color_index
                    ? parseInt(color_index as string)
                    : undefined,
                  sizeIndex: size_index
                    ? parseInt(size_index as string)
                    : undefined,
                  image: image?.id,
                  imageUrl: image ? getImage(image.image) : undefined,
                };
              }
            ) as IFormProductVariant[],
            coverImage: (data?.cover_image?.id || "") as string,
            coverSquareImage: (data?.cover_square_image?.id || "") as string,
            productImages: (data?.product_images || []).map((elm) => ({
              value: elm.id,
              url: getImage(elm.image),
            })) as IProductImage[],
          }}
          type="edit"
        />
      )}
    </>
  );
};

export default EditProduct;
