import axios from "axios";
import { IUserInput } from "./types";
import { IStatus } from "../../interfaces/global";

export const getMe = async () => {
  return await axios.get("/get-me");
};

export const getAll = async () => {
  const paginatedData = await axios.get(
    `${process.env.REACT_APP_API_ENPOINT}/admin/user`
  );

  return paginatedData;
};

export const getOne = async (id: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_ENPOINT}/admin/user/${id}`
  );

  return data;
};

export const insert = async (input: IUserInput) => {
  return await axios.post(`${process.env.REACT_APP_API_ENPOINT}/admin/user`, {
    ...input,
  });
};

export const update = async (id: string, input: IUserInput) => {
  return await axios.put(
    `${process.env.REACT_APP_API_ENPOINT}/admin/user/${id}`,
    {
      ...input,
    }
  );
};

export const destroy = async (id: string) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_ENPOINT}/admin/user/${id}`
  );
};

export const updateStatus = async (id: string, status: IStatus) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ENPOINT}/admin/user/${id}/change-status`,
    {
      status,
    }
  );
};
