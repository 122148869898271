import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";
import { Link } from "react-router-dom";
import { FormCustomerProps } from "./types";
import FormPassword from "../../components/forms/FormPassword";
import { passwordPattern } from "../../constants/global";
import { useEffect } from "react";
import FormCountry from "../../components/forms/FormCountry";
import { ICountryData } from "../../services/countryApi/types";

const FormCustomer: React.FC<FormCustomerProps> = ({
  onSubmit,
  isLoadingSave,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,
    trigger,
    control,
    setValue,
  } = useForm({
    defaultValues,
  });

  const watchPassword = watch("password");
  const watchPhoneCode = watch("phoneCode");
  const watchCompanyPhoneCode = watch("companyPhoneCode");
  const watchDeliveryPhoneCode = watch("deliveryPhoneCode");

  useEffect(() => {
    if (isSubmitted) {
      trigger("confirmPassword");
    }
  }, [watchPassword, isSubmitted]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Customer Details</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="firstName"
                      md="3"
                    >
                      First Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="firstName"
                        type="text"
                        {...replaceRefToInneRef(
                          register("firstName", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("firstName")}
                      />
                      {errors.hasOwnProperty("firstName") && (
                        <FormFeedback>First Name is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="lastName"
                      md="3"
                    >
                      Last Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="lastName"
                        type="text"
                        {...replaceRefToInneRef(
                          register("lastName", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("lastName")}
                      />
                      {errors.hasOwnProperty("lastName") && (
                        <FormFeedback>Last Name is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="phoneNumber"
                      md="3"
                    >
                      Country
                    </Label>
                    <Col md="9">
                      <Controller
                        control={control}
                        name="country"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid },
                        }) => (
                          <FormCountry
                            invalid={invalid}
                            value={value}
                            onChange={(data: ICountryData) => {
                              onChange(data?.nicename || "");
                              setValue("phoneCode", data?.phonecode || "");
                            }}
                          />
                        )}
                      />
                      {errors.hasOwnProperty("country") && (
                        <FormFeedback className="d-block">
                          Country is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="phoneNumber"
                      md="3"
                    >
                      Phone Number
                    </Label>
                    <Col md="9">
                      <InputGroup>
                        {watchPhoneCode && (
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>{watchPhoneCode}</InputGroupText>
                          </InputGroupAddon>
                        )}
                        <Input
                          id="phoneNumber"
                          type="text"
                          {...replaceRefToInneRef(
                            register("phoneNumber", { required: true })
                          )}
                          invalid={errors.hasOwnProperty("phoneNumber")}
                        />
                      </InputGroup>
                      {errors.hasOwnProperty("phoneNumber") && (
                        <FormFeedback>Phone Number is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="email"
                      md="3"
                    >
                      Email
                    </Label>
                    <Col md="9">
                      <Input
                        id="email"
                        type="text"
                        {...replaceRefToInneRef(
                          register("email", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("email")}
                      />
                      {errors.hasOwnProperty("email") && (
                        <FormFeedback>Email is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="password"
                      md="3"
                    >
                      Password
                    </Label>
                    <Col md="9">
                      <FormPassword
                        {...register("password", {
                          minLength: 8,
                          pattern: passwordPattern,
                        })}
                        placeholder="Password"
                        id="password"
                        invalid={errors.hasOwnProperty("password")}
                        feedbackElements={
                          <>
                            {errors?.password?.type === "minLength" && (
                              <FormFeedback>
                                The minimum password length is 8!
                              </FormFeedback>
                            )}
                            {errors?.password?.type === "pattern" && (
                              <FormFeedback>
                                The password should contain at least 1
                                lowercase, 1 uppercase, 1 special character, and
                                1 number.
                              </FormFeedback>
                            )}
                          </>
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="confirmPassword"
                      md="3"
                    >
                      Confirm Password
                    </Label>
                    <Col md="9">
                      <FormPassword
                        {...register("confirmPassword", {
                          minLength: 8,
                          pattern: passwordPattern,
                          validate: {
                            required: (val) => {
                              if (watchPassword && val === "") {
                                return "Confirm Password is required!";
                              }
                            },
                            passwordNotMatch: (val) => {
                              if (watchPassword !== val) {
                                return "The password fields do not match!";
                              }
                            },
                          },
                        })}
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        invalid={errors.hasOwnProperty("confirmPassword")}
                        feedbackElements={
                          <>
                            {errors?.confirmPassword?.type === "minLength" && (
                              <FormFeedback>
                                The minimum password length is 8!
                              </FormFeedback>
                            )}
                            {errors?.confirmPassword?.type === "pattern" && (
                              <FormFeedback>
                                The password should contain at least 1
                                lowercase, 1 uppercase, 1 special character, and
                                1 number.
                              </FormFeedback>
                            )}
                            {(errors?.confirmPassword?.type === "required" ||
                              errors?.confirmPassword?.type ===
                                "passwordNotMatch") && (
                              <FormFeedback>
                                {errors.confirmPassword.message}
                              </FormFeedback>
                            )}
                          </>
                        }
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Company Details</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="companyName"
                      md="3"
                    >
                      Company Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="companyName"
                        type="text"
                        {...replaceRefToInneRef(
                          register("companyName", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("companyName")}
                      />
                      {errors.hasOwnProperty("companyName") && (
                        <FormFeedback>Company Name is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="companyAddress"
                      md="3"
                    >
                      Company Address
                    </Label>
                    <Col md="9">
                      <Input
                        id="companyAddress"
                        type="textarea"
                        {...replaceRefToInneRef(
                          register("companyAddress", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("companyAddress")}
                      />
                      {errors.hasOwnProperty("companyAddress") && (
                        <FormFeedback>
                          Company Address is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="companyCountry"
                      md="3"
                    >
                      Company Country
                    </Label>
                    <Col md="9">
                      <Controller
                        control={control}
                        name="companyCountry"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid },
                        }) => (
                          <FormCountry
                            invalid={invalid}
                            value={value}
                            onChange={(data: ICountryData) => {
                              onChange(data?.nicename || "");
                              setValue(
                                "companyPhoneCode",
                                data?.phonecode || ""
                              );
                            }}
                          />
                        )}
                      />
                      {errors.hasOwnProperty("companyCountry") && (
                        <FormFeedback>
                          Company Country is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="companyPhoneNumber"
                      md="3"
                    >
                      Company Phone Number
                    </Label>
                    <Col md="9">
                      <InputGroup>
                        {watchCompanyPhoneCode && (
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {watchCompanyPhoneCode}
                            </InputGroupText>
                          </InputGroupAddon>
                        )}
                        <Input
                          id="companyPhoneNumber"
                          type="text"
                          {...replaceRefToInneRef(
                            register("companyPhoneNumber", { required: true })
                          )}
                          invalid={errors.hasOwnProperty("companyPhoneNumber")}
                        />
                        {errors.hasOwnProperty("companyPhoneNumber") && (
                          <FormFeedback>
                            Company Phone Number is required!
                          </FormFeedback>
                        )}
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="companyState"
                      md="3"
                    >
                      Company State
                    </Label>
                    <Col md="9">
                      <Input
                        id="companyState"
                        type="text"
                        {...replaceRefToInneRef(
                          register("companyState", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("companyState")}
                      />
                      {errors.hasOwnProperty("companyState") && (
                        <FormFeedback>Company State is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="companyCity"
                      md="3"
                    >
                      Company City
                    </Label>
                    <Col md="9">
                      <Input
                        id="companyCity"
                        type="text"
                        {...replaceRefToInneRef(
                          register("companyCity", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("companyCity")}
                      />
                      {errors.hasOwnProperty("companyCity") && (
                        <FormFeedback>Company City is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="companyZipCode"
                      md="3"
                    >
                      Company Zip Code
                    </Label>
                    <Col md="9">
                      <Input
                        id="companyZipCode"
                        type="text"
                        {...replaceRefToInneRef(
                          register("companyZipCode", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("companyZipCode")}
                      />
                      {errors.hasOwnProperty("companyZipCode") && (
                        <FormFeedback>
                          Company Zip Code is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="companyRecipientName"
                      md="3"
                    >
                      Company Recipient Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="companyRecipientName"
                        type="text"
                        {...replaceRefToInneRef(
                          register("companyRecipientName", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("companyRecipientName")}
                      />
                      {errors.hasOwnProperty("companyRecipientName") && (
                        <FormFeedback>
                          Company Recipient Name is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Delivery Details</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryAddress"
                      md="3"
                    >
                      Delivery Address
                    </Label>
                    <Col md="9">
                      <Input
                        id="deliveryAddress"
                        type="textarea"
                        {...replaceRefToInneRef(
                          register("deliveryAddress", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("deliveryAddress")}
                      />
                      {errors.hasOwnProperty("deliveryAddress") && (
                        <FormFeedback>
                          Delivery Address is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryCountry"
                      md="3"
                    >
                      Delivery Country
                    </Label>
                    <Col md="9">
                      <Controller
                        control={control}
                        name="deliveryCountry"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid },
                        }) => (
                          <FormCountry
                            invalid={invalid}
                            value={value}
                            onChange={(data: ICountryData) => {
                              onChange(data?.nicename || "");
                              setValue(
                                "deliveryPhoneCode",
                                data?.phonecode || ""
                              );
                            }}
                          />
                        )}
                      />
                      {errors.hasOwnProperty("deliveryCountry") && (
                        <FormFeedback>
                          Delivery Country is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryPhoneNumber"
                      md="3"
                    >
                      Delivery Phone Number
                    </Label>
                    <Col md="9">
                      <InputGroup>
                        {watchDeliveryPhoneCode && (
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {watchDeliveryPhoneCode}
                            </InputGroupText>
                          </InputGroupAddon>
                        )}
                        <Input
                          id="deliveryPhoneNumber"
                          type="text"
                          {...replaceRefToInneRef(
                            register("deliveryPhoneNumber", { required: true })
                          )}
                          invalid={errors.hasOwnProperty("deliveryPhoneNumber")}
                        />

                        {errors.hasOwnProperty("deliveryPhoneNumber") && (
                          <FormFeedback>
                            Delivery Phone Number is required!
                          </FormFeedback>
                        )}
                      </InputGroup>
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryState"
                      md="3"
                    >
                      Delivery State
                    </Label>
                    <Col md="9">
                      <Input
                        id="deliveryState"
                        type="text"
                        {...replaceRefToInneRef(
                          register("deliveryState", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("deliveryState")}
                      />
                      {errors.hasOwnProperty("deliveryState") && (
                        <FormFeedback>Delivery State is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryCity"
                      md="3"
                    >
                      Delivery City
                    </Label>
                    <Col md="9">
                      <Input
                        id="deliveryCity"
                        type="text"
                        {...replaceRefToInneRef(
                          register("deliveryCity", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("deliveryCity")}
                      />
                      {errors.hasOwnProperty("deliveryCity") && (
                        <FormFeedback>Delivery City is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="description"
                      md="3"
                    >
                      Delivery ZIP Code
                    </Label>
                    <Col md="9">
                      <Input
                        id="deliveryZipCode"
                        type="text"
                        {...replaceRefToInneRef(
                          register("deliveryZipCode", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("deliveryZipCode")}
                      />
                      {errors.hasOwnProperty("deliveryZipCode") && (
                        <FormFeedback>
                          Delivery Zip Code is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="description"
                      md="3"
                    >
                      Delivery Recipient Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="deliveryRecipientName"
                        type="text"
                        {...replaceRefToInneRef(
                          register("deliveryRecipientName", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("deliveryRecipientName")}
                      />
                      {errors.hasOwnProperty("deliveryRecipientName") && (
                        <FormFeedback>
                          Delivery Recipient Name is required!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Button type="submit" color="primary" disabled={isLoadingSave}>
              {isLoadingSave && <span>Loading...</span>}
              {!isLoadingSave && <span>Save</span>}
            </Button>
            <Link className="btn btn-secondary" to="/admin/customer">
              Cancel
            </Link>
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default FormCustomer;
