import {
  Input,
  Button,
  Modal,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import { ModalStatusProps } from "./types";
import { useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";

const ModalStatus: React.FC<ModalStatusProps> = ({
  isShow,
  onClose,
  onSubmit,
  isLoadingConfirm,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  return (
    <Modal className="modal-dialog-centered" isOpen={isShow} toggle={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Change Order Status
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <Label>Status</Label>
            <Input
              type="select"
              {...replaceRefToInneRef(register("status", { required: true }))}
              invalid={errors.hasOwnProperty("status")}
            >
              <option value="">Select Status</option>
              <option value="New Order">New Order</option>
              <option value="Negotiation">Negotiation</option>
              <option value="Order Processed">Order Processed</option>
              <option value="Finished">Finished</option>
              <option value="Cancelled">Cancelled</option>
            </Input>
            {errors.hasOwnProperty("status") && (
              <FormFeedback>Status is required!</FormFeedback>
            )}
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="submit"
            className="rounded-pill"
            disabled={!isValid || isLoadingConfirm}
          >
            {isLoadingConfirm ? "Loading..." : "Save"}
          </Button>

          <Button
            className="ml-auto rounded-pill"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalStatus;
