import React, { useState } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "../../components/Headers/AuthHeader";
import { useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";
import { Redirect } from "react-router";
import { setIsLogin, login } from "../../redux/reducers/auth";
import { setMe } from "../../redux/reducers/user";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { useAppDispatch, useAppSelector } from "../../redux/store";

function Login() {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);

  const { register, handleSubmit } = useForm();

  const [redirect, setRedirect] = useState(false);

  const dispatch = useAppDispatch();

  const { isLoadingLogin } = useAppSelector((state) => state.auth);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  const onSubmit = async (data: any) => {
    try {
      const response = await dispatch(login(data)).unwrap();

      dispatch(setMe(response.user));
      dispatch(setIsLogin(true));

      setRedirect(true);
    } catch (err: any) {
      showNotificationAlert({
        type: "danger",
        title: "Failed",
        message: err,
      });
    }
  };

  if (redirect) {
    return <Redirect to="/admin/dashboard" />;
  }

  return (
    <>
      <AuthHeader />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        {...replaceRefToInneRef(
                          register("email", { required: true })
                        )}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        {...replaceRefToInneRef(
                          register("password", { required: true })
                        )}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="black"
                      type="submit"
                      disabled={isLoadingLogin}
                    >
                      {isLoadingLogin && <span>Loading...</span>}
                      {!isLoadingLogin && <span>Sign in</span>}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
