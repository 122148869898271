import axios from "axios";
import { IStatus } from "../../views/customer/ModalChangeStatus";
import { ICustomerInput } from "./types";

export const getAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_ENPOINT}/admin/customer`
  );

  return data;
};

export const getOne = async (id: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_ENPOINT}/admin/customer/${id}`
  );

  return data;
};

export const insert = async (input: ICustomerInput) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ENPOINT}/admin/customer`,
    { ...input }
  );
};

export const update = async (id: string, input: ICustomerInput) => {
  return await axios.put(
    `${process.env.REACT_APP_API_ENPOINT}/admin/customer/${id}`,
    {
      ...input,
    }
  );
};

export const destroy = async (id: string) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_ENPOINT}/admin/customer/${id}`
  );
};

export const updateStatus = async (id: string, status: IStatus) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ENPOINT}/admin/customer/${id}/change-status`,
    {
      status,
    }
  );
};
