import React, { useState, useEffect } from "react";
import FormUser from "./FormUser";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Redirect, useParams } from "react-router";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { Link } from "react-router-dom";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { IFormUser } from "./types";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getOne, update } from "../../redux/reducers/user";

const EditUser: React.FC = () => {
  const { id }: { id: string } = useParams();

  const { isLoadingData, isLoadingSave, data } = useAppSelector(
    (state) => state.user
  );

  console.log("__data", isLoadingData, data);

  const dispatch = useAppDispatch();

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    dispatch(getOne(id));
  }, []);

  const onSubmit = async (data: IFormUser) => {
    const { email, password, confirmPassword, firstName, lastName } = data;

    try {
      await dispatch(
        update({
          id,
          input: {
            email,
            password: password as string,
            password_confirmation: confirmPassword as string,
            first_name: firstName,
            last_name: lastName,
            status: "",
            role: "",
          },
        })
      ).unwrap();

      showNotificationAlert({
        title: "Success",
        message: "You have updated the user successfully",
      });

      setIsRedirect(true);
    } catch (err: any) {
      showNotificationAlert({
        type: "danger",
        title: "Failed",
        message: err,
      });
    }
  };

  if (isRedirect) {
    return <Redirect to="/admin/user" />;
  }

  return (
    <>
      <SimpleHeader
        name="Edit User"
        parents={[{ to: "/admin/user", label: "User" }]}
        rightElements={
          <Link
            to="/admin/user"
            className="btn btn-neutral btn-sm"
            color="default"
          >
            Back
          </Link>
        }
      />
      {isLoadingData && (
        <Container className="mt--6" fluid>
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0">User Details</h3>
              </CardHeader>
              <CardBody>Loading...</CardBody>
            </Card>
          </div>
        </Container>
      )}
      {!isLoadingData && data && (
        <FormUser
          onSubmit={onSubmit}
          isLoadingSave={isLoadingSave}
          defaultValues={{
            email: data.email,
            firstName: data.first_name,
            lastName: data.last_name,
            role: "",
          }}
        />
      )}
    </>
  );
};

export default EditUser;
