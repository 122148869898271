import React, { useCallback, useEffect, useState, LegacyRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Button,
  UncontrolledTooltip,
  FormGroup,
  Table,
} from "reactstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import RowItem from "../../../components/SortableRow/RowItem";
import classnames from "classnames";
import useConfirmation from "../../../hooks/useConfirmation";
import { IFormProductColorWithKey, ProductOptionColorProps } from "../types";
import UppyUploader from "../../../components/UppyUploader";

const ProductOptionColor: React.FC<ProductOptionColorProps> = ({
  onChange,
  values,
  error,
}) => {
  const [colorValue, setColorValue] = useState("");
  const [valueNotAllowed, setValueNotAllowed] = useState(false);

  const [productColors, setProductColors] = useState<
    IFormProductColorWithKey[]
  >([]);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setProductColors(
      values?.map((value, index) => {
        return {
          ...value,
          currentIndex: index,
        };
      }) || []
    );
    setCurrentIndex(values?.length || 0);
  }, [values]);

  const { showConfirmation, hideConfirmation } = useConfirmation();

  const insertProductColor = () => {
    const newProductColors = [
      ...productColors,
      {
        currentIndex,
        name: colorValue,
        image: "",
        imageUrl: "",
      },
    ];

    setCurrentIndex(currentIndex + 1);

    setColorValue("");
    setProductColors(newProductColors);
    onChange(newProductColors);
  };

  const moveCard: (dragIndex: number, hoverIndex: number) => void = useCallback(
    (dragIndex, hoverIndex) => {
      const newProductColors = update(productColors, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, productColors[dragIndex]],
        ],
      });

      setProductColors(newProductColors);
      onChange(newProductColors);
    },
    [productColors, onChange]
  );

  const onChangeProductColor = useCallback(
    (index: number, field: any, value: string) => {
      const newProductionColors = [...productColors];

      newProductionColors[index] = {
        ...newProductionColors[index],
        [field]: value,
      };

      setProductColors(newProductionColors);
      onChange(newProductionColors);
    },
    [productColors, setProductColors, onChange]
  );

  const renderItem = useCallback(
    (productColor: IFormProductColorWithKey, index: number) => {
      const removeItem = (index: number) => {
        const newProductColors = [...productColors];
        newProductColors.splice(index, 1);

        setProductColors(newProductColors);
        onChange(newProductColors);

        hideConfirmation();
      };

      return (
        <RowItem
          id={productColor.currentIndex.toString()}
          key={productColor.currentIndex}
          index={index}
          moveCard={moveCard}
        >
          {({ dragRef, previewRef, handlerId, isDragging }) => (
            <tr
              ref={previewRef as LegacyRef<HTMLTableRowElement>}
              data-handler-id={handlerId}
              className={classnames("sortable-row")}
              style={{ opacity: isDragging ? 0 : 1 }}
            >
              <td>
                <div
                  ref={dragRef}
                  className={classnames("sortable-row__pointer", {
                    "sortable-row__pointer--dragging": isDragging,
                  })}
                >
                  <i className="fas fa-sort"></i>
                </div>
              </td>
              <td>
                <label className="form-control-label col-form-label">
                  {productColor.name}
                </label>
              </td>
              <td>
                <UppyUploader
                  currentImage={productColor.imageUrl}
                  title="Color Image"
                  onChange={(imageId: string) => {
                    onChangeProductColor(index, "image", imageId);
                  }}
                />
              </td>
              <td>
                <button
                  type="button"
                  className="table-action table-action-delete"
                  id={`tooltip-delete-${productColor.currentIndex}`}
                  onClick={(e) => {
                    e.preventDefault();
                    showConfirmation({
                      title: "Are you sure want to delete?",
                      onConfirm: () => {
                        removeItem(index);
                      },
                    });
                  }}
                >
                  <i className="fas fa-trash" />
                </button>
                <UncontrolledTooltip
                  delay={0}
                  target={`tooltip-delete-${productColor.currentIndex}`}
                >
                  Delete Product Color
                </UncontrolledTooltip>
              </td>
            </tr>
          )}
        </RowItem>
      );
    },
    [
      moveCard,
      productColors,
      onChangeProductColor,
      showConfirmation,
      hideConfirmation,
      onChange,
    ]
  );

  return (
    <Row>
      <Col>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h3 className="mb-0 text-brand">
                    Product Colors / Motif
                    {error ? (
                      <span className="d-inline-block ml-2 invalid-feedback font-weight-normal w-auto">
                        - {error}
                      </span>
                    ) : (
                      ""
                    )}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup className="row mb-0">
                <Col md="7">
                  <Input
                    placeholder="Product colors / motif name"
                    type="text"
                    value={colorValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      setColorValue(value);
                      if (
                        productColors.findIndex(
                          (elm) =>
                            elm.name.toLowerCase() === value.toLowerCase()
                        ) >= 0
                      ) {
                        setValueNotAllowed(true);
                      } else {
                        setValueNotAllowed(false);
                      }
                    }}
                  />
                </Col>
                <Col md="5">
                  <Button
                    type="button"
                    color="neutral"
                    onClick={() => {
                      insertProductColor();
                    }}
                    disabled={colorValue === "" || valueNotAllowed}
                  >
                    Add Color / Motif
                  </Button>
                </Col>
              </FormGroup>

              {productColors.length >= 1 && (
                <Table
                  className="align-items-center table-flush mt-4"
                  responsive
                  striped
                >
                  <thead className="thead-light">
                    <tr>
                      <th />
                      <th>Color / Motif</th>
                      <th>Image</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <DndProvider backend={HTML5Backend}>
                      {productColors.map((productColor, index) =>
                        renderItem(productColor, index)
                      )}
                    </DndProvider>
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default ProductOptionColor;
