import { Card, CardBody, CardHeader, Container } from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import AccountForm from "./AccountForm";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setMe } from "../../redux/reducers/user";
import { IFormAccount } from "./types";

const Account: React.FC = () => {
  const { show: showNotificationAlert } = useNotificationAlert({
    title: "Success",
    message: "You have updated your account successfully!",
    place: "br",
  });

  const { me, isLoadingSave } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const onSubmit = async (
    { firstName, lastName, password }: IFormAccount,
    callback: () => void
  ) => {
    // const input = {
    //   firstName,
    //   lastName,
    // };
    // if (password) {
    //   input.password = password;
    // }
    // const { data } = await myAccountUpdate({
    //   variables: {
    //     input,
    //   },
    // });
    // dispatch(setMe({ ...me, ...data.myAccountUpdate }));
    // callback();
    //showNotificationAlert();
  };

  return (
    <>
      <SimpleHeader name="My Account" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <h3 className="mb-0">Account Detail</h3>
            </CardHeader>
            <CardBody>
              <AccountForm
                isLoadingSave={isLoadingSave}
                defaultValues={{
                  firstName: me?.first_name,
                  lastName: me?.last_name,
                }}
                email={me?.email as string}
                onSubmit={onSubmit}
              />
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default Account;
