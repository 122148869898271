import axios from "axios";
import { IProductInput } from "../productApi/types";
import queryString from "query-string";
import { ISimpleQueryParams } from "../../interfaces/global";

export const getAll = async (query?: ISimpleQueryParams) => {
  const { data } = await axios.get(
    queryString.stringifyUrl({
      url: `${process.env.REACT_APP_API_ENPOINT}/admin/product`,
      query: {
        page: query?.page,
        key: query?.key,
        per_page: query?.per_page,
      },
    })
  );

  return data;
};

export const getOne = async (id: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_ENPOINT}/admin/product/${id}`
  );

  return data;
};

export const insert = async (input: IProductInput) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ENPOINT}/admin/product`,
    {
      ...input,
    }
  );
};

export const update = async (id: string, input: IProductInput) => {
  return await axios.put(
    `${process.env.REACT_APP_API_ENPOINT}/admin/product/${id}`,
    { ...input }
  );
};

export const destroy = async (id: string) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_ENPOINT}/admin/product/${id}`
  );
};
