import { useContext, useEffect } from "react";
import AppContext from "../contexts/AppContext";

const useConfirmation = (isLoadingConfirm?: boolean) => {
  const { setConfirmation } = useContext(AppContext);

  useEffect(() => {
    if (setConfirmation) {
      setConfirmation((localConfirmation) => {
        return {
          ...localConfirmation,
          isLoadingConfirm,
        };
      });
    }
  }, [isLoadingConfirm, setConfirmation]);

  const showConfirmation = (data: any) => {
    if (setConfirmation) {
      setConfirmation((localConfirmation) => {
        const returnData: any = {
          ...localConfirmation,
          isShow: true,
        };

        if (data?.type !== undefined) {
          returnData.type = data.type;
        } else {
          returnData.type = "sweetAlert";
        }

        if (data?.title !== undefined) {
          returnData.title = data.title;
        }

        if (data?.description !== undefined) {
          returnData.description = data.description;
        }

        if (data?.onConfirm !== undefined) {
          returnData.onConfirm = data.onConfirm;
        }

        if (data?.onCancel !== undefined) {
          returnData.onCancel = data.onCancel;
        }

        if (data?.confirmTitle !== undefined) {
          returnData.confirmTitle = data.confirmTitle;
        }

        if (data?.cancelTitle !== undefined) {
          returnData.cancelTitle = data.cancelTitle;
        }

        return returnData;
      });
    }
  };

  const hideConfirmation = () => {
    if (setConfirmation) {
      setConfirmation((localConfirmation) => {
        return {
          ...localConfirmation,
          isShow: false,
        };
      });
    }
  };

  return {
    showConfirmation,
    hideConfirmation,
  };
};

export default useConfirmation;
