import { useState, useCallback } from "react";
import { IFile } from "../types";
import UploaderPreview from "../Preview";
import useConfirmation from "../../../hooks/useConfirmation";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RowItem from "../../SortableRow/RowItem";
import { Col, UncontrolledTooltip } from "reactstrap";
import classNames from "classnames";

const DragDropPreview: React.FC<{ files: IFile[] }> = ({ files }) => {
  const [previews, setPreviews] = useState<string>();

  const [isLoading, setIsloading] = useState(false);

  const onRemove = (e: any) => {
    setPreviews("");
    // onChange("");
  };

  const { showConfirmation, hideConfirmation } = useConfirmation();

  const removeFile = (id: string) => {
    const newFiles = [...files];
    const index = files.findIndex((file) => file.id === id);
    newFiles.splice(index, 1);
    // setFiles(newFiles);
    hideConfirmation();
  };

  const moveCard: (dragIndex: number, hoverIndex: number) => void = useCallback(
    (dragIndex, hoverIndex) => {
      /*setProductOptions((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
      */
    },
    []
  );

  const renderItem = useCallback(
    (file: IFile, index: number) => {
      const removeItem = (index: number) => {
        // const newProductOptions = [...productOptions];
        // newProductOptions.splice(index, 1);

        // setProductOptions(newProductOptions);
        // onChange(newProductOptions);

        hideConfirmation();
      };

      return (
        <RowItem id={file.id} key={file.id} index={index} moveCard={moveCard}>
          {({ dragRef, previewRef, handlerId, isDragging }) => (
            <div
              className="row row--product-options row--product-options--with-delete sortable-row"
              ref={previewRef}
              data-handler-id={handlerId}
              style={{ opacity: isDragging ? 0 : 1 }}
            >
              <Col>
                <div
                  ref={dragRef}
                  className={classNames("sortable-row__pointer", {
                    "sortable-row__pointer--dragging": isDragging,
                  })}
                >
                  <i className="fas fa-sort"></i>
                </div>
              </Col>
              <Col>
                <UploaderPreview image={file.url} />
              </Col>
              <Col>
                {/*<button
                  type="button"
                  className="table-action table-action-delete"
                  id={`tooltip-delete-${file.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    showConfirmation({
                      title: "Are you sure want to delete?",
                      onConfirm: () => {
                        removeItem(index);
                      },
                    });
                  }}
                >
                  <i className="fas fa-trash" />
                </button>
                <UncontrolledTooltip
                  delay={0}
                  target={`tooltip-delete-${file.id}`}
                >
                  Delete Image
                </UncontrolledTooltip>
                */}
              </Col>
            </div>
          )}
        </RowItem>
      );
    },
    [
      moveCard,
      showConfirmation,
      hideConfirmation,
      //  onChange
    ]
  );

  return (
    <div className="mt-2">
      <DndProvider backend={HTML5Backend}>
        {files.map((file, index) => renderItem(file, index))}
      </DndProvider>
    </div>
  );
};

export default DragDropPreview;
