import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import categoryReducer from "./reducers/category";
import productReducer from "./reducers/product";
import accountReducer from "./reducers/account";
import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";
import customerReducer from "./reducers/customer";
import countryReducer from "./reducers/country";
import orderReducer from "./reducers/order";
import adminReducer from "./reducers/admin";
import mediaReducer from "./reducers/media";

const store = configureStore({
  reducer: {
    category: categoryReducer,
    product: productReducer,
    account: accountReducer,
    auth: authReducer,
    user: userReducer,
    customer: customerReducer,
    country: countryReducer,
    order: orderReducer,
    admin: adminReducer,
    media: mediaReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
