import { Button } from "reactstrap";
import { UploaderPreviewProps } from "./types";

const UploaderPreview: React.FC<UploaderPreviewProps> = ({
  image,
  onRemove,
}) => {
  return (
    <div className="uploader-preview">
      <img alt="Preview" src={image} />
      <Button size="sm" color="link" type="button" onClick={onRemove}>
        Remove
      </Button>
    </div>
  );
};

export default UploaderPreview;
