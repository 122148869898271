import { useEffect, useState } from "react";
import { getMe } from "../redux/reducers/user";
import { Route, Switch } from "react-router";
import AdminLayout from "./Admin";
import AuthLayout from "./Auth";
import { useAppDispatch } from "../redux/store";
import { setIsLogin } from "../redux/reducers/auth";

const AuthenticatedOrNot = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const callGetMe = async () => {
      try {
        await dispatch(getMe()).unwrap();
        setIsLoading(false);
        dispatch(setIsLogin(true));
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };

    callGetMe();
  }, [dispatch]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout />} />
      <Route path="/auth" render={(props) => <AuthLayout />} />
    </Switch>
  );
};

export default AuthenticatedOrNot;
