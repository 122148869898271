import React, { useState } from "react";
import FormProduct from "./FormProduct";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { insert } from "../../redux/reducers/product";
import { IFormProduct } from "./types";

const AddPRoduct: React.FC = () => {
  const [isRedirect, setIsRedirect] = useState(false);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  const dispatch = useAppDispatch();

  const { isLoadingSave } = useAppSelector((state) => state.product);

  const onSubmit = async (data: IFormProduct) => {
    const {
      productCode,
      productName,
      slug,
      coverImage,
      coverSquareImage,
      categories,
      description,
      status,
      productImages,
      options,
      variants,
    } = data;

    try {
      await dispatch(
        insert({
          product_code: productCode,
          product_name: productName,
          slug: slug,
          categories: categories.map(({ categoryId, ...rest }) => ({
            ...rest,
            category_id: categoryId,
          })),
          description,
          status_available: status,
          cover_image_id: coverImage.toString(),
          cover_square_image_id: coverSquareImage.toString(),
          product_images: productImages.map((elm) => elm.value.toString()),
          options: {
            colors: options?.colors.map(({ name, image }) => ({
              name,
              image_id: image,
            })),
            sizes: options?.sizes?.map(({ name }) => ({
              name,
            })),
          },
          product_variation: variants?.map(
            ({ image, sku, colorIndex, sizeIndex, price }) => {
              const color_index = options?.colors.findIndex(
                //(elm) => elm.currentIndex === colorIndex
                (_, index) => index === colorIndex
              );
              const size_index = options?.sizes?.findIndex(
                (_, index) => index === sizeIndex
              );

              return {
                image_id: image,
                sku,
                color_index: color_index,
                size_index: size_index,
                price: parseInt(price),
              };
            }
          ),
        })
      ).unwrap();

      showNotificationAlert({
        title: "Success",
        message: "You have added a product successfully",
      });

      setIsRedirect(true);
    } catch (err: any) {
      showNotificationAlert({
        type: "danger",
        title: "Failed",
        message: err,
      });
    }
  };

  if (isRedirect) {
    return <Redirect to="/admin/product" />;
  }

  return (
    <>
      <SimpleHeader
        name="Add Product"
        parents={[{ to: "/admin/product", label: "Product" }]}
        rightElements={
          <Link
            to="/admin/product"
            className="btn btn-neutral btn-sm"
            color="default"
          >
            Back
          </Link>
        }
      />
      <FormProduct
        onSubmit={onSubmit}
        isLoadingSave={isLoadingSave}
        type="add"
      />
    </>
  );
};

export default AddPRoduct;
