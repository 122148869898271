import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAll as apiGetAll,
  getOne as apiGetOne,
  insert as apiInsert,
  update as apiUpdate,
  destroy as apiDestroy,
  getCategoryTree as apiGetCategoryTree,
} from "../../services/categoryApi";
import { ICategory, ICategoryInput } from "../../services/categoryApi/types";
import { IPaginatedData, ISimpleQueryParams } from "../../interfaces/global";

export const getAll = createAsyncThunk(
  "category/getAll",
  async (query?: ISimpleQueryParams) => {
    const paginatedData = await apiGetAll(query);
    return paginatedData;
  }
);

export const getCategoryTree = createAsyncThunk(
  "category/getCategoryTree",
  async (query?: ISimpleQueryParams) => {
    const paginatedData = await apiGetCategoryTree(query);
    return paginatedData;
  }
);

export const fetchParentLists = createAsyncThunk(
  "category/fetchParentLists",
  async (query?: ISimpleQueryParams) => {
    const paginatedData = await apiGetCategoryTree(query);
    return paginatedData;
  }
);

export const getOne = createAsyncThunk(
  "category/getOne",
  async (id: string) => {
    const data = await apiGetOne(id);
    return data;
  }
);

export const insert = createAsyncThunk(
  "category/insert",
  async (input: ICategoryInput, { rejectWithValue }) => {
    try {
      await apiInsert(input);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const update = createAsyncThunk(
  "category/update",
  async (
    { id, input }: { id: string; input: ICategoryInput },
    { rejectWithValue }
  ) => {
    try {
      await apiUpdate(id, input);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const destroy = createAsyncThunk(
  "category/destroy",
  async (id: string) => {
    await apiDestroy(id);
    return true;
  }
);

interface CategorySliceState {
  isLoadingDatas: boolean;
  paginatedData: IPaginatedData<ICategory> | null;
  parentLists: IPaginatedData<ICategory> | null;
  isLoadingData: boolean;
  data?: ICategory;
  isLoadingSave: boolean;
  isLoadingDestroy: boolean;
}

const initialState: CategorySliceState = {
  isLoadingDatas: false,
  paginatedData: null,
  parentLists: null,
  isLoadingData: false,
  data: undefined,
  isLoadingSave: false,
  isLoadingDestroy: false,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * GET ALL
     */
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.paginatedData = action.payload;
      state.isLoadingDatas = false;
    });

    /**
     * CATEGORY TREE
     */
    builder.addCase(getCategoryTree.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getCategoryTree.fulfilled, (state, action) => {
      state.paginatedData = action.payload;
      state.isLoadingDatas = false;
    });

    /**
     * fetchParentLists
     */
    builder.addCase(fetchParentLists.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(fetchParentLists.fulfilled, (state, action) => {
      state.parentLists = action.payload;
      state.isLoadingDatas = false;
    });

    /**
     * GET ONE
     */
    builder.addCase(getOne.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(getOne.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });

    /**
     * INSERT
     */
    builder.addCase(insert.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(insert.rejected, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(insert.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    /**
     * UPDATE
     */
    builder.addCase(update.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(update.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(update.rejected, (state) => {
      state.isLoadingSave = false;
    });

    /**
     * DESTROY
     */
    builder.addCase(destroy.pending, (state) => {
      state.isLoadingDestroy = true;
    });

    builder.addCase(destroy.fulfilled, (state) => {
      state.isLoadingDestroy = false;
    });
  },
});

export default categorySlice.reducer;
