import axios from "axios";
import { IAssignInput, IOrderStatus } from "./types";

export const getAll = async (role: string, orderStatus?: IOrderStatus) => {
  let url = `${process.env.REACT_APP_API_ENPOINT}/admin/order`;

  if (orderStatus) {
    url += `?status=${orderStatus}`;
  }

  const { data } = await axios.get(url);

  return data;
};

export const getOne = async (id: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_ENPOINT}/admin/order/${id}`
  );

  return data;
};

export const assignOrder = async (id: string, input: IAssignInput) => {
  return await axios.put(
    `${process.env.REACT_APP_API_ENPOINT}/admin/order/${id}/assign`,
    {
      ...input,
    }
  );
};

export const updateQtyOrder = async (id: string, qty: number) => {
  return await axios.patch(
    `${process.env.REACT_APP_API_ENPOINT}/admin/update-qty-order/${id}`,
    {
      qty,
    }
  );
};

export const addDiscountOrder = async (id: string, discount: number) => {
  return await axios.put(
    `${process.env.REACT_APP_API_ENPOINT}/admin/order/${id}/add-discount`,
    {
      discount,
    }
  );
};

export const updateStatusOrder = async (id: string, status: string) => {
  return await axios.put(
    `${process.env.REACT_APP_API_ENPOINT}/admin/order/${id}/update-status`,
    {
      status,
    }
  );
};
