import { useEffect } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";
import { ICustomerData } from "../../services/customerApi/types";

export type IStatus = "active" | "deactive";

interface IFormData {
  status?: IStatus;
}

export interface ModalChangeStatusProps {
  isShow: boolean;
  onClose: () => void;
  onSubmit: (data: IFormData) => void;
  customerData?: ICustomerData;
  isLoadingConfirm?: boolean;
}

const ModalChangeStatus: React.FC<ModalChangeStatusProps> = ({
  isShow,
  onClose,
  onSubmit,
  customerData,
  isLoadingConfirm,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("status", customerData?.status);
  }, [customerData]);

  return (
    <Modal className="modal-dialog-centered" isOpen={isShow} toggle={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Change Customer Status
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>You will change status customer {customerData?.first_name}</p>
          <FormGroup>
            <Label>Change Status</Label>
            <Input
              type="select"
              {...replaceRefToInneRef(register("status", { required: true }))}
              invalid={errors.hasOwnProperty("status")}
            >
              <option value="">Select Status</option>
              <option value="active">Active</option>
              <option value="deactive">Deactive</option>
            </Input>
            {errors.hasOwnProperty("status") && (
              <FormFeedback>Status is required!</FormFeedback>
            )}
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="submit"
            id="buttonCopy"
            className="rounded-pill"
            disabled={isLoadingConfirm}
          >
            {isLoadingConfirm ? "Loading..." : "Sure!"}
          </Button>

          <Button
            className="ml-auto rounded-pill"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalChangeStatus;
