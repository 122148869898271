import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import DragDrop from "../../components/UppyUploader/DragDrop";
import { IFile } from "../../components/UppyUploader/types";
import { ProductImagesProps } from "./types";
import { v4 as uuidv4 } from "uuid";

const ProductImages: React.FC<ProductImagesProps> = ({
  onChange,
  values,
  error,
}) => {
  return (
    <Row>
      <Col>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h3 className="mb-0 text-brand">
                    Product Images
                    {error ? (
                      <span className="d-inline-block ml-2 invalid-feedback font-weight-normal w-auto">
                        - {error}
                      </span>
                    ) : (
                      ""
                    )}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DragDrop
                values={values?.map((elm) => ({
                  id: uuidv4(),
                  fileId: elm.value,
                  url: elm.url,
                }))}
                onChange={(data: IFile[]) => {
                  onChange(
                    data.map((elm) => ({
                      value: elm.fileId.toString(),
                      url: elm.url,
                    }))
                  );
                }}
              />
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default ProductImages;
