import { logout, setIsLogin } from "../redux/reducers/auth";
import { setMe } from "../redux/reducers/user";
import { useAppDispatch, useAppSelector } from "../redux/store";
import useConfirmation from "./useConfirmation";

function useOnLogout(onSuccess: () => void) {
  const dispatch = useAppDispatch();

  const { isLoadingLogout } = useAppSelector((state) => state.auth);

  const { showConfirmation, hideConfirmation } =
    useConfirmation(isLoadingLogout);

  const onLogout = () => {
    showConfirmation({
      title: "Are you sure you want to logout?",
      onConfirm: async () => {
        try {
          await dispatch(logout());
          dispatch(setIsLogin(false));
          dispatch(setMe(null));
          hideConfirmation();
          onSuccess();
        } catch (err: any) {
          console.log("__err", err.message);
        }
      },
    });
  };

  return {
    onLogout,
  };
}

export default useOnLogout;
