import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes, RowItemProps } from "./ItemTypes";

const RowItem: React.FC<RowItemProps> = ({
  id,
  index,
  moveCard,
  children,
  onDrop,
}) => {
  const dragRef = useRef<HTMLDivElement>(null);

  const previewRef = useRef<HTMLDivElement | HTMLTableRowElement>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.ROWITEM,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.ROWITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item: any, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset?.y || 0 - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop(item: any, monitor) {
      if (onDrop) {
        onDrop(item, monitor);
      }
    },
  });

  drag(dragRef);
  drop(preview(previewRef));

  return children({ dragRef, previewRef, handlerId, isDragging });
};

export default RowItem;
