import { useState } from "react";
import Confirmation from "../../components/Confirmation";
import ModalConfirmation from "../../components/Confirmation/ModalConfirmation";
import { AppContextProvider } from "../../contexts/AppContext";
import NotificationAlert from "react-notification-alert";
import { useRef } from "react";
import { AppProps } from "./types";

const App: React.FC<AppProps> = ({ children }) => {
  const [confirmation, setConfirmation] = useState({
    isShow: false,
    type: "sweetAlert",
    onCancel: () =>
      setConfirmation((localConfirmation) => {
        return {
          ...localConfirmation,
          isShow: false,
        };
      }),
  });

  const notificationAlertRef = useRef<NotificationAlert>(null);

  const { type, ...restConfirmation } = confirmation;

  return (
    <AppContextProvider
      value={{
        setConfirmation,
        notificationAlertRef,
      }}
    >
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {type === "sweetAlert" && <Confirmation {...restConfirmation} />}
      {type === "modal" && <ModalConfirmation {...restConfirmation} />}
      {children}
    </AppContextProvider>
  );
};

export default App;
