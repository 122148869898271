import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAll as apiGetAll,
  destroy as apiDestroy,
} from "../../services/mediaApi";
import { IMedia } from "../../services/mediaApi/types";
import { IPaginatedData, ISimpleQueryParams } from "../../interfaces/global";

export const getAll = createAsyncThunk(
  "category/getAll",
  async (query?: ISimpleQueryParams) => {
    const paginatedData = await apiGetAll(query);
    return paginatedData;
  }
);

export const destroy = createAsyncThunk(
  "category/destroy",
  async (id: string) => {
    await apiDestroy(id);
    return true;
  }
);

interface MediaSliceState {
  isLoadingDatas: boolean;
  paginatedData: IPaginatedData<IMedia> | null;
  isLoadingDestroy: boolean;
}

const initialState: MediaSliceState = {
  isLoadingDatas: false,
  paginatedData: null,
  isLoadingDestroy: false,
};

export const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * GET ALL
     */
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.paginatedData = action.payload;
      state.isLoadingDatas = false;
    });

    /**
     * DESTROY
     */
    builder.addCase(destroy.pending, (state) => {
      state.isLoadingDestroy = true;
    });

    builder.addCase(destroy.fulfilled, (state) => {
      state.isLoadingDestroy = false;
    });
  },
});

export default mediaSlice.reducer;
