import React, { forwardRef, useState } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import classnames from "classnames";
import { FormPasswordProps } from "./types";

const FormPassword = forwardRef<
  HTMLInputElement,
  FormPasswordProps & React.HTMLAttributes<HTMLInputElement>
>(({ invalid, feedbackElements, ...props }, ref) => {
  const [focused, setFocused] = useState<boolean>(false);

  const [revealed, setRevealed] = useState<boolean>();

  return (
    <InputGroup
      className={classnames({
        "input-group-merge": !invalid,
        focused: focused && !invalid,
      })}
    >
      <Input
        innerRef={ref}
        {...props}
        type={revealed ? "text" : "password"}
        invalid={invalid}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText
          onClick={() => {
            setRevealed(!revealed);
          }}
        >
          <i className="fas fa-eye" />
        </InputGroupText>
      </InputGroupAddon>
      {feedbackElements}
    </InputGroup>
  );
});

export default FormPassword;
