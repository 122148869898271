import { Container } from "reactstrap";

function AuthHeader() {
  return (
    <>
      <div className="header bg-gradient-black py-7 py-lg-8 pt-lg-9">
        <Container>
          <div className="header-body text-center mb-7">
            <img
              alt="Logo"
              style={{ maxWidth: "100px" }}
              src={require("../../../assets/img/brand/logo-3-white.png")}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default AuthHeader;
