import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import store from "./redux/store";
import AppLayout from "./layouts/App";
import AuthenticatedOrNot from "./layouts/AuthenticatedOrNot";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/scss/main.scss?v1.2.0";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/file-input/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_ENPOINT;
axios.defaults.withCredentials = true;

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppLayout>
          <Switch>
            <Route
              path={["/admin", "/auth"]}
              render={() => <AuthenticatedOrNot />}
            />
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
        </AppLayout>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
