import {
  Card,
  CardHeader,
  CardBody,
  Label,
  Row,
  Container,
  Col,
  Table,
  Button,
  Collapse,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  addDiscountOrder,
  getOne,
  updateQtyOrder,
  updateStatusOrder,
} from "../../redux/reducers/order";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import ModalDiscount from "./ModalDiscount";
import ModalQty from "./ModalQty";
import ModalStatus from "./ModalStatus";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { ModalQtyProps } from "./types";
import ModalProduct from "./ModalProduct";
import useConfirmation from "../../hooks/useConfirmation";

const OrderDetail: React.FC = () => {
  const { id }: { id: string } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOne(id));
  }, []);

  const { showConfirmation, hideConfirmation } = useConfirmation();

  const {
    isLoadingData,
    data,
    isLoadingAddDiscountOrder,
    isLoadingUpdateQtyOrder,
    isLoadingUpdateStatusOrder,
  } = useAppSelector((state) => state.order);

  const [status, setStatus] = useState();

  const [discount, setDiscount] = useState(0);

  const [items, setItems] = useState(data?.items);

  useEffect(() => {
    setItems(data?.items);
  }, [data]);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  const [modalProduct, setModalProduct] = useState({
    isShow: false,
  });

  const [modalDiscount, setModalDiscount] = useState({
    isShow: false,
  });

  const [modalQty, setModalQty] = useState<ModalQtyProps>({
    isShow: false,
  });

  const [modalStatus, setModalStatus] = useState({
    isShow: false,
  });

  const onDeleteProduct = (id: string) => {};

  const [open, setOpen] = useState(false);

  return (
    <>
      {data && (
        <ModalDiscount
          isLoadingConfirm={isLoadingAddDiscountOrder}
          onClose={() => {
            setModalDiscount({
              ...modalDiscount,
              isShow: false,
            });
          }}
          onSubmit={async (value) => {
            try {
              await dispatch(
                addDiscountOrder({
                  id: data?.id as string,
                  discount: value.discount as number,
                })
              ).unwrap();

              setModalDiscount({
                ...modalDiscount,
                isShow: false,
              });

              showNotificationAlert({
                type: "success",
                title: "Success",
                message: "Discount updated!",
              });
            } catch (err: any) {
              showNotificationAlert({
                type: "danger",
                title: "Failed",
                message: err.message,
              });
            }
          }}
          defaultValues={{
            discount: data?.total_discount,
          }}
          {...modalDiscount}
        />
      )}

      <ModalQty
        isLoadingConfirm={isLoadingUpdateQtyOrder}
        onClose={() => {
          setModalQty({
            ...modalQty,
            isShow: false,
          });
        }}
        onSubmit={async (orderItemId, value) => {
          try {
            await dispatch(
              updateQtyOrder({
                id: orderItemId,
                qty: value.qty as number,
              })
            ).unwrap();

            setModalQty({
              ...modalQty,
              isShow: false,
            });

            showNotificationAlert({
              type: "success",
              title: "Success",
              message: "Qty updated!",
            });
          } catch (err: any) {
            showNotificationAlert({
              type: "danger",
              title: "Failed",
              message: err.message,
            });
          }
        }}
        {...modalQty}
      />

      <ModalProduct
        isLoadingConfirm={isLoadingUpdateQtyOrder}
        onClose={() => {
          setModalProduct({
            ...modalProduct,
            isShow: false,
          });
        }}
        onSubmit={(formData) => {
          console.log("Formdata Product:", formData);
          /*
          try {
            await dispatch(
              updateQtyOrder({
                id: orderItemId,
                qty: value.qty as number,
              })
            ).unwrap();

            setModalQty({
              ...modalQty,
              isShow: false,
            });

            showNotificationAlert({
              type: "success",
              title: "Success",
              message: "Qty updated!",
            });
          } catch (err: any) {
            showNotificationAlert({
              type: "danger",
              title: "Failed",
              message: err.message,
            });
          }
          */
        }}
        {...modalProduct}
      />

      {data && (
        <ModalStatus
          isLoadingConfirm={isLoadingUpdateStatusOrder}
          onClose={() => {
            setModalStatus({
              ...modalStatus,
              isShow: false,
            });
          }}
          onSubmit={async (value) => {
            try {
              await dispatch(
                updateStatusOrder({
                  id: data?.id as string,
                  status: value.status as string,
                })
              ).unwrap();

              setModalStatus({
                ...modalStatus,
                isShow: false,
              });

              showNotificationAlert({
                type: "success",
                title: "Success",
                message: "Order Status updated!",
              });
            } catch (err: any) {
              showNotificationAlert({
                type: "danger",
                title: "Failed",
                message: err.message,
              });
            }
          }}
          defaultValues={{
            status: data?.status,
          }}
          {...modalStatus}
        />
      )}

      <SimpleHeader
        name="Detail Order"
        parents={[{ to: "/admin/order", label: "Orders" }]}
        rightElements={
          <Link
            to="/admin/order"
            className="btn btn-neutral btn-sm"
            color="default"
          >
            Back
          </Link>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Order Details</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="firstName"
                      md="3"
                    >
                      Invoice
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">
                        {data?.invoice_number}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="firstName"
                      md="3"
                    >
                      Customer Name
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">
                        {data?.customer_name}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="phoneNumber"
                      md="3"
                    >
                      Total Volume
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">{data?.total_volume}</div>
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="phoneNumber"
                      md="3"
                    >
                      Order Status
                    </Label>
                    <Col md="9">
                      <div className="d-flex align-items-center">
                        <div className="col-form-label">{data?.status}</div>{" "}
                        <Button
                          onClick={() => {
                            setModalStatus({
                              ...modalStatus,
                              isShow: true,
                            });
                          }}
                          size="sm"
                          className="ml-2"
                          color="black"
                        >
                          Change Order Status
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0" onClick={() => setOpen(!open)}>
                    Company Details
                  </h3>
                </CardHeader>
                <Collapse isOpen={open}>
                  <CardBody>
                    <Row>
                      <Label
                        className="form-control-label"
                        htmlFor="companyName"
                        md="3"
                      >
                        Company Name
                      </Label>
                      <Col md="9">
                        <div className="col-form-label">
                          {data?.company_name}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Label
                        className="form-control-label"
                        htmlFor="companyAddress"
                        md="3"
                      >
                        Company Address
                      </Label>
                      <Col md="9">
                        <div className="col-form-label">
                          {data?.company_address}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Label
                        className="form-control-label"
                        htmlFor="companyCountry"
                        md="3"
                      >
                        Company Country
                      </Label>
                      <Col md="9">
                        <div className="col-form-label">
                          {data?.company_country}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Label
                        className="form-control-label"
                        htmlFor="companyPhoneNumber"
                        md="3"
                      >
                        Company Phone Number
                      </Label>
                      <Col md="9">
                        <div className="col-form-label">
                          {data?.company_phone_number}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Label
                        className="form-control-label"
                        htmlFor="companyState"
                        md="3"
                      >
                        Company State
                      </Label>
                      <Col md="9">
                        <div className="col-form-label">
                          {data?.company_state}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Label
                        className="form-control-label"
                        htmlFor="companyCity"
                        md="3"
                      >
                        Company City
                      </Label>
                      <Col md="9">
                        <div className="col-form-label">
                          {data?.company_city}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Label
                        className="form-control-label"
                        htmlFor="companyZipCode"
                        md="3"
                      >
                        Company Zip Code
                      </Label>
                      <Col md="9">
                        <div className="col-form-label">
                          {data?.company_zip_code}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Label
                        className="form-control-label"
                        htmlFor="companyRecipientName"
                        md="3"
                      >
                        Company Recipient Name
                      </Label>
                      <Col md="9">
                        <div className="col-form-label">
                          {data?.company_receipent_name}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Delivery Details</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryAddress"
                      md="3"
                    >
                      Delivery Address
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">
                        {data?.delivery_address}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryCountry"
                      md="3"
                    >
                      Delivery Country
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">
                        {data?.delivery_country}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryPhoneNumber"
                      md="3"
                    >
                      Delivery Phone Number
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">
                        {data?.delivery_phone_number}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryState"
                      md="3"
                    >
                      Delivery State
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">
                        {data?.delivery_state}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="deliveryCity"
                      md="3"
                    >
                      Delivery City
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">
                        {data?.delivery_city}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="description"
                      md="3"
                    >
                      Delivery ZIP Code
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">
                        {data?.delivery_zip_code}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      className="form-control-label"
                      htmlFor="description"
                      md="3"
                    >
                      Delivery Recipient Name
                    </Label>
                    <Col md="9">
                      <div className="col-form-label">
                        {data?.delivery_receipent_name}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <h3 className="mb-0">Order Items</h3>
                    </Col>
                    <Col md="6" className="text-right">
                      <Button
                        size="sm"
                        color="black"
                        onClick={() => {
                          setModalProduct({
                            ...modalProduct,
                            isShow: true,
                          });
                        }}
                      >
                        Add Product
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <Table
                  className="align-items-center table-flush"
                  responsive
                  striped
                >
                  <thead className="thead-light">
                    <tr>
                      <th>SKU</th>
                      <th>Product</th>
                      <th>Product Name</th>
                      <th>Option</th>
                      <th>Price</th>
                      <th>Qty</th>
                      <th>Discount</th>
                      <th>Total</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {data?.items.map((item) => (
                      <tr key={item.id}>
                        <td>{item.sku}</td>
                        <td></td>
                        <td>{item.product_name}</td>
                        <td></td>
                        <td>{item.price}</td>
                        <td>
                          <Button
                            size="sm"
                            className="ml-2"
                            onClick={() => {
                              setModalQty({
                                ...modalQty,
                                isShow: true,
                                orderItemId: item.id,
                                qty: item.qty,
                              });
                            }}
                          >
                            {item.qty}
                          </Button>
                        </td>
                        <td></td>
                        <td>{item.qty * item.price}</td>
                        <td className="table-actions">
                          <div className="d-flex">
                            <button
                              className="table-action table-action-unarchive"
                              id="tooltip-unarchive-135062fb-0e8e-4dcb-84a8-7d267f8ac4a7"
                            >
                              <i className="fas fa-list"></i>
                            </button>
                            <button
                              className="table-action table-action-delete"
                              id="tooltip-delete-135062fb-0e8e-4dcb-84a8-7d267f8ac4a7"
                              onClick={() => {
                                showConfirmation({
                                  title: "Are you sure want to delete?",
                                  onConfirm: () => onDeleteProduct(data.id),
                                });
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Total</h3>
                </CardHeader>
                <CardBody>
                  <Row className="justify-content-end">
                    <Col lg="6">
                      <Row>
                        <Label
                          className="form-control-label"
                          htmlFor="deliveryAddress"
                          md="7"
                        >
                          Subtotal
                        </Label>
                        <Col md="5" className="text-right">
                          <div className="col-form-label">{data?.total}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Label
                          className="form-control-label"
                          htmlFor="deliveryAddress"
                          md="7"
                        >
                          <div className="d-flex align-items-center">
                            <span>Discount</span>
                            <Button
                              onClick={() => {
                                setModalDiscount({
                                  ...modalDiscount,
                                  isShow: true,
                                });
                              }}
                              size="sm"
                              className="ml-2"
                              color="black"
                            >
                              Set Discount
                            </Button>
                          </div>
                        </Label>
                        <Col md="5" className="text-right">
                          <div className="col-form-label">
                            {data?.total_discount}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Label
                          className="form-control-label"
                          htmlFor="deliveryAddress"
                          md="7"
                        >
                          Total
                        </Label>
                        <Col md="5" className="text-right">
                          <div className="col-form-label">
                            {data?.grand_total}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrderDetail;
