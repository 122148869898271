import Dashboard from "../views/Dashboard";
import Category from "../views/category";
import AddCategory from "../views/category/AddCategory";
import EditCategory from "../views/category/EditCategory";
import Product from "../views/product";
import AddProduct from "../views/product/AddProduct";
import EditProduct from "../views/product/EditProduct";
import Account from "../views/Account";
import Login from "../views/auth/Login";
import ResetPassword from "../views/auth/ResetPassword";
import { IRoute } from "../interfaces/global";
import Customer from "../views/customer";
import User from "../views/user";
import EditCustomer from "../views/customer/EditCustomer";
import Order, {
  OrderCancelled,
  OrderFinished,
  OrderNegotiation,
  OrderNew,
  OrderProcessed,
} from "../views/order";
import OrderDetail from "../views/order/OrderDetail";
import EditUser from "../views/user/EditUser";
import AddUser from "../views/user/AddUser";
import Image from "../views/image";

const routes: IRoute[] = [
  {
    collapse: false,
    name: "Dashboard",
    icon: "ni ni-compass-04",
    state: "dashboardCollapse",
    path: "/dashboard",
    layout: "/admin",
    component: Dashboard,
    accessLevels: ["developer", "super-admin"],
  },
  {
    collapse: false,
    name: "Categories",
    icon: "ni ni-satisfied",
    state: "category",
    path: "/category",
    component: Category,
    layout: "/admin",
    accessLevels: ["developer"],
  },
  {
    collapse: false,
    name: "Products",
    icon: "ni ni-app",
    state: "product",
    path: "/product",
    layout: "/admin",
    component: Product,
    accessLevels: ["developer"],
  },
  {
    collapse: false,
    name: "Images",
    icon: "ni ni-app",
    state: "image",
    path: "/image",
    layout: "/admin",
    component: Image,
    accessLevels: ["developer"],
  },
  {
    collapse: false,
    name: "Customers",
    icon: "ni ni-app",
    state: "product",
    path: "/customer",
    layout: "/admin",
    component: Customer,
    accessLevels: ["super-admin"],
  },
  {
    collapse: false,
    name: "Users",
    icon: "ni ni-app",
    state: "product",
    path: "/user",
    layout: "/admin",
    component: User,
    accessLevels: ["super-admin"],
  },
  {
    path: "/user/:id/edit",
    name: "User Detail",
    miniName: "D",
    component: EditUser,
    layout: "/admin",
    hideFromSidebar: true,
    accessLevels: ["super-admin"],
  },
  {
    path: "/user/add",
    name: "Add User",
    miniName: "D",
    component: AddUser,
    layout: "/admin",
    hideFromSidebar: true,
    accessLevels: ["super-admin"],
  },
  {
    collapse: true,
    name: "Orders",
    icon: "ni ni-app",
    state: "product",
    path: "/orders",
    layout: "/admin",
    component: Order,
    accessLevels: ["super-admin", "admin"],
    views: [
      {
        path: "/orders/new",
        miniName: "NO",
        name: "New Order",
        component: OrderNew,
        accessLevels: ["super-admin", "admin"],
        layout: "/admin",
      },
      {
        path: "/orders/negotiation",
        miniName: "N",
        name: "Negotiation",
        component: OrderNegotiation,
        accessLevels: ["super-admin", "admin"],
        layout: "/admin",
      },
      {
        path: "/orders/order-processed",
        miniName: "OP",
        name: "Order Processed",
        component: OrderProcessed,
        accessLevels: ["super-admin", "admin"],
        layout: "/admin",
      },
      {
        path: "/orders/finished",
        miniName: "F",
        name: "Finished",
        component: OrderFinished,
        accessLevels: ["super-admin", "admin"],
        layout: "/admin",
      },
      {
        path: "/orders/cancelled",
        miniName: "C",
        name: "Cancelled",
        component: OrderCancelled,
        accessLevels: ["super-admin", "admin"],
        layout: "/admin",
      },
    ],
  },
  {
    path: "/order/:id",
    name: "Order Detail",
    miniName: "D",
    component: OrderDetail,
    layout: "/admin",
    hideFromSidebar: true,
    accessLevels: ["super-admin", "admin"],
  },
  {
    path: "/account",
    name: "Account",
    icon: "ni ni-circle-08",
    miniName: "A",
    component: Account,
    layout: "/admin",
    accessLevels: ["developer", "super-admin"],
  },
  {
    path: "/category/add",
    name: "Add Category",
    miniName: "A",
    component: AddCategory,
    layout: "/admin",
    hideFromSidebar: true,
    accessLevels: ["developer"],
  },
  {
    path: "/category/:id/edit",
    name: "Edit Category",
    miniName: "D",
    component: EditCategory,
    layout: "/admin",
    hideFromSidebar: true,
    accessLevels: ["developer"],
  },
  {
    path: "/product/add",
    name: "Add Product",
    miniName: "A",
    component: AddProduct,
    layout: "/admin",
    hideFromSidebar: true,
    accessLevels: ["developer"],
  },
  {
    path: "/product/:id/edit",
    name: "Edit Product",
    miniName: "D",
    component: EditProduct,
    layout: "/admin",
    hideFromSidebar: true,
    accessLevels: ["developer"],
  },
  {
    path: "/customer/:id/edit",
    name: "Edit Customer",
    miniName: "D",
    component: EditCustomer,
    layout: "/admin",
    hideFromSidebar: true,
    accessLevels: ["developer"],
  },
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
    hideFromSidebar: true,
    accessLevels: ["developer"],
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    miniName: "R",
    component: ResetPassword,
    layout: "/auth",
    hideFromSidebar: true,
    accessLevels: ["developer"],
  },
];

export default routes;
