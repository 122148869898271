import { createContext, Dispatch } from "react";
import NotificationAlert from "react-notification-alert";

interface IAlertContext {
  setConfirmation?: Dispatch<
    React.SetStateAction<{
      isShow: boolean;
      type: string;
      onCancel: () => void;
    }>
  >;
  notificationAlertRef?: React.RefObject<NotificationAlert>;
}

const AppContext = createContext<IAlertContext>({});

export const AppContextProvider = AppContext.Provider;

export default AppContext;
