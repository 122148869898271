import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  FormFeedback,
} from "reactstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";
import UppyUploader from "../../components/UppyUploader";
import { Link, useParams } from "react-router-dom";
import { FormCategoryProps } from "./types";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchParentLists } from "../../redux/reducers/category";
import slugify from "slugify";
import { slugPattern } from "../../constants/global";

const FormCategory: React.FC<FormCategoryProps> = ({
  onSubmit,
  isLoadingSave,
  defaultValues,
  imagePreview,
  type,
}) => {
  const { id }: { id: string } = useParams();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues,
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const dispatch = useAppDispatch();

  const { isLoadingDatas, parentLists } = useAppSelector(
    (state) => state.category
  );

  useEffect(() => {
    dispatch(fetchParentLists({ per_page: 100 }));
  }, [dispatch]);

  const watchName = watch("name");

  useEffect(() => {
    if (!dirtyFields.hasOwnProperty("slug") && watchName && type === "add") {
      setValue("slug", slugify(watchName, { lower: true }));
    }
  }, [watchName, dirtyFields, setValue, type]);

  const parentDatas = useMemo(() => {
    let localParentLists = parentLists?.data || [];

    if (id) {
      localParentLists = localParentLists.filter((elm) => {
        return (
          Number(elm.id) !== Number(id) && Number(elm.parent_id) !== Number(id)
        );
      });
    }

    return localParentLists;
  }, [id, parentLists]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Category Details</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup className="row">
                    <Label className="form-control-label" htmlFor="name" md="3">
                      Name
                    </Label>
                    <Col md="9">
                      <Input
                        id="name"
                        type="text"
                        {...replaceRefToInneRef(
                          register("name", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("name")}
                      />
                      {errors.hasOwnProperty("name") && (
                        <FormFeedback>Name is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="form-control-label" htmlFor="name" md="3">
                      Slug
                    </Label>
                    <Col md="9">
                      <Input
                        id="slug"
                        type="text"
                        {...replaceRefToInneRef(
                          register("slug", {
                            required: true,
                            pattern: slugPattern,
                          })
                        )}
                        invalid={errors.hasOwnProperty("slug")}
                      />
                      {errors.hasOwnProperty("slug") && (
                        <>
                          {errors.slug?.type === "required" && (
                            <FormFeedback>Slug is required!</FormFeedback>
                          )}
                          {errors.slug?.type === "pattern" && (
                            <FormFeedback>Wrong slug!</FormFeedback>
                          )}
                        </>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="3"
                    >
                      Image
                    </Label>
                    <Col md="9">
                      <Controller
                        control={control}
                        name="image"
                        render={({ field: { onChange, value } }) => (
                          <UppyUploader
                            currentImage={imagePreview}
                            title="Choose Image"
                            onChange={(imageId: string) => {
                              onChange(imageId);
                            }}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="description"
                      md="3"
                    >
                      Parent Category
                    </Label>
                    <Col md="9">
                      {isLoadingDatas && <p>Loading...</p>}
                      {!isLoadingDatas && (
                        <Input
                          id="parent_id"
                          type="select"
                          {...replaceRefToInneRef(register("parent_id"))}
                        >
                          <option value="0">None</option>
                          {parentDatas?.map((data) => (
                            <option value={data.id} key={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </Input>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="description"
                      md="3"
                    >
                      Description
                    </Label>
                    <Col md="9">
                      <Input
                        id="description"
                        type="textarea"
                        {...replaceRefToInneRef(
                          register("description", { required: true })
                        )}
                        invalid={errors.hasOwnProperty("description")}
                      />
                      {errors.hasOwnProperty("description") && (
                        <FormFeedback>Description is required!</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Button type="submit" color="primary" disabled={isLoadingSave}>
              {isLoadingSave && <span>Loading...</span>}
              {!isLoadingSave && <span>Save</span>}
            </Button>
            <Link className="btn btn-secondary" to="/admin/category">
              Cancel
            </Link>
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default FormCategory;
