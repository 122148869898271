import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAll as apiGetAll,
  getOne as apiGetOne,
  assignOrder as apiAssignOrder,
  updateQtyOrder as apiUpdateQtyOrder,
  addDiscountOrder as apiAddDiscountOrder,
  updateStatusOrder as apiUpdateStatusOrder,
} from "../../services/orderApi";
import {
  IAssignInput,
  IOrder,
  IOrderStatus,
} from "../../services/orderApi/types";

export const getAll = createAsyncThunk(
  "order/getAll",
  async ({
    role,
    orderStatus,
  }: {
    role: string;
    orderStatus?: IOrderStatus;
  }) => {
    const { data } = await apiGetAll(role, orderStatus);
    return data;
  }
);

export const getOne = createAsyncThunk("order/getOne", async (id: string) => {
  const data = await apiGetOne(id);
  return data;
});

export const assignOrder = createAsyncThunk(
  "order/assignOrder",
  async (
    { id, input }: { id: string; input: IAssignInput },
    { rejectWithValue }
  ) => {
    try {
      await apiAssignOrder(id, input);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateQtyOrder = createAsyncThunk(
  "order/updateQtyOrder",
  async ({ id, qty }: { id: string; qty: number }, { rejectWithValue }) => {
    try {
      await apiUpdateQtyOrder(id, qty);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addDiscountOrder = createAsyncThunk(
  "order/addDiscountOrder",
  async (
    { id, discount }: { id: string; discount: number },
    { rejectWithValue }
  ) => {
    try {
      await apiAddDiscountOrder(id, discount);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateStatusOrder = createAsyncThunk(
  "order/updateStatusOrder",
  async (
    { id, status }: { id: string; status: string },
    { rejectWithValue }
  ) => {
    try {
      await apiUpdateStatusOrder(id, status);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

interface CustomerSliceState {
  isLoadingDatas: boolean;
  datas: IOrder[];
  isLoadingData: boolean;
  isLoadingUpdateQtyOrder: boolean;
  isLoadingAddDiscountOrder: boolean;
  isLoadingUpdateStatusOrder: boolean;
  data?: IOrder;
  isLoadingSave: boolean;
}

const initialState: CustomerSliceState = {
  isLoadingDatas: false,
  datas: [],
  isLoadingData: false,
  isLoadingUpdateQtyOrder: false,
  isLoadingAddDiscountOrder: false,
  isLoadingUpdateStatusOrder: false,
  data: undefined,
  isLoadingSave: false,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.datas = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getAll.rejected, (state, action) => {
      state.isLoadingDatas = false;
    });

    builder.addCase(getOne.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(getOne.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(assignOrder.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(assignOrder.fulfilled, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(assignOrder.rejected, (state) => {
      state.isLoadingSave = false;
    });

    builder.addCase(updateStatusOrder.pending, (state) => {
      state.isLoadingUpdateStatusOrder = true;
    });

    builder.addCase(updateStatusOrder.fulfilled, (state, action) => {
      state.isLoadingUpdateStatusOrder = false;
      if (state.data) {
        state.data.status = action.meta.arg.status;
      }
    });

    builder.addCase(updateStatusOrder.rejected, (state) => {
      state.isLoadingUpdateStatusOrder = false;
    });

    builder.addCase(addDiscountOrder.pending, (state) => {
      state.isLoadingAddDiscountOrder = true;
    });

    builder.addCase(addDiscountOrder.fulfilled, (state, action) => {
      state.isLoadingAddDiscountOrder = false;
      if (state.data) {
        state.data.total_discount = action.meta.arg.discount;
      }
    });

    builder.addCase(addDiscountOrder.rejected, (state) => {
      state.isLoadingAddDiscountOrder = false;
    });

    builder.addCase(updateQtyOrder.pending, (state) => {
      state.isLoadingUpdateQtyOrder = true;
    });

    builder.addCase(updateQtyOrder.fulfilled, (state, action) => {
      state.isLoadingUpdateQtyOrder = false;
      if (state.data) {
        const { id, qty } = action.meta.arg;
        const selectedIndex = state.data.items.findIndex(
          (elm) => elm.id === id
        );
        state.data.items[selectedIndex].qty = qty;
      }
    });

    builder.addCase(updateQtyOrder.rejected, (state) => {
      state.isLoadingUpdateQtyOrder = false;
    });
  },
});

export default orderSlice.reducer;
