import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login as apiLogin, logout as apiLogout } from "../../services/authApi";
import { ILoginInput } from "../../services/authApi/types";

export const login = createAsyncThunk(
  "auth/login",
  async (loginInput: ILoginInput, { rejectWithValue }) => {
    try {
      const { data } = await apiLogin(loginInput);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  const { data } = await apiLogout();
  return data;
});

interface IAuthSliceState {
  isLogin: boolean;
  isLoadingLogin: boolean;
  isLoadingLogout: boolean;
}

const initialState: IAuthSliceState = {
  isLogin: false,
  isLoadingLogin: false,
  isLoadingLogout: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLogin(state, action) {
      state.isLogin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoadingLogin = true;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.isLogin = true;
      state.isLoadingLogin = false;
    });

    builder.addCase(login.rejected, (state) => {
      state.isLoadingLogin = false;
    });

    builder.addCase(logout.pending, (state) => {
      state.isLoadingLogout = true;
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.isLogin = false;
      state.isLoadingLogout = false;
    });
  },
});

export const { setIsLogin } = authSlice.actions;

export default authSlice.reducer;
