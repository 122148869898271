import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getMe as apiGetMe,
  getAll as apiGetAll,
  getOne as apiGetOne,
  update as apiUpdate,
  insert as apiInsert,
  updateStatus as apiUpdateStatus,
  destroy as apiDestroy,
} from "../../services/userApi";
import { IUser, IUserInput } from "../../services/userApi/types";
import { IPaginatedData, IStatus } from "../../interfaces/global";

export const getMe = createAsyncThunk("auth/getMe", async () => {
  const { data } = await apiGetMe();
  return data;
});

export const getAll = createAsyncThunk("user/getAll", async () => {
  const { data } = await apiGetAll();
  return data;
});

export const getOne = createAsyncThunk("user/getOne", async (id: string) => {
  const data = await apiGetOne(id);

  return data;
});

export const insert = createAsyncThunk(
  "user/insert",
  async (input: IUserInput, { rejectWithValue }) => {
    try {
      await apiInsert(input);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const update = createAsyncThunk(
  "user/update",
  async (
    { id, input }: { id: string; input: IUserInput },
    { rejectWithValue }
  ) => {
    try {
      await apiUpdate(id, input);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "user/updateStatus",
  async (
    { id, status }: { id: string; status: IStatus },
    { rejectWithValue }
  ) => {
    try {
      await apiUpdateStatus(id, status);
      return true;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const destroy = createAsyncThunk(
  "category/destroy",
  async (id: string) => {
    await apiDestroy(id);
    return true;
  }
);

interface IAuthSliceState {
  me: IUser | null;
  isLoadingDatas: boolean;
  paginatedData: IPaginatedData<IUser> | null;
  isLoadingGetMe: boolean;
  isLoadingData: boolean;
  isLoadingSave: boolean;
  data?: IUser;
  isLoadingDestroy: boolean;
}

const initialState: IAuthSliceState = {
  me: null,
  isLoadingDatas: false,
  paginatedData: null,
  isLoadingSave: false,
  isLoadingGetMe: false,
  isLoadingData: false,
  isLoadingDestroy: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setMe(state, action) {
      state.me = action.payload;
    },
    setStatus(state, action) {
      // const index = state.datas.findIndex(
      //   (elm) => elm.id === action.payload.id
      // );
      // state.datas[index].status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.pending, (state) => {
      state.isLoadingGetMe = true;
    });

    builder.addCase(getMe.fulfilled, (state, action) => {
      state.me = action.payload.user;
      state.isLoadingGetMe = false;
    });

    builder.addCase(getMe.rejected, (state) => {
      state.isLoadingGetMe = false;
    });

    /**
     * GET ALL
     */
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.paginatedData = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getAll.rejected, (state, action) => {
      state.isLoadingDatas = false;
    });

    /**
     * GET ONE
     */
    builder.addCase(getOne.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(getOne.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoadingData = false;
    });
  },
});

export const { setMe, setStatus } = userSlice.actions;

export default userSlice.reducer;
