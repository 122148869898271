import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import { ProductVariantProps, IFormProductVariant } from "./types";
import UppyUploader from "../../components/UppyUploader";

const ProductVariants: React.FC<ProductVariantProps> = ({
  colors,
  sizes,
  onChange,
  values,
  error,
}) => {
  const [productVariants, setProductVariants] = useState<IFormProductVariant[]>(
    []
  );

  useEffect(() => {
    setProductVariants(values || []);
  }, [values]);

  const onChangeProductVariant = useCallback(
    (
      field: any,
      value: string,
      colorCurrentIndex?: number,
      sizeCurrentIndex?: number
    ) => {
      const newProductionVariants = [...productVariants];

      const index = newProductionVariants.findIndex(
        (elm) =>
          elm.colorIndex === colorCurrentIndex &&
          elm.sizeIndex === sizeCurrentIndex
      );

      newProductionVariants[index] = {
        ...newProductionVariants[index],
        [field]: value,
      };

      setProductVariants(newProductionVariants);
      onChange(newProductionVariants);
    },
    [productVariants, setProductVariants, onChange]
  );

  return (
    <Row>
      <Col>
        <div className="card-wrapper">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="6">
                  <h3 className="mb-0 text-brand">
                    Product Variants
                    {error ? (
                      <span className="d-inline-block ml-2 invalid-feedback font-weight-normal w-auto">
                        - {error}
                      </span>
                    ) : (
                      ""
                    )}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {productVariants.map(
                ({ colorIndex, sizeIndex, sku, imageUrl }) => {
                  const colorData = colors?.find(
                    (_, index) => index === colorIndex
                  );

                  const colorName = colorData?.name || "";

                  const sizeData = sizes?.find(
                    (_, index) => index === sizeIndex
                  );

                  let sizeName = sizeData ? `${sizeData.name}` : "";

                  return (
                    <div className="row" key={`${colorIndex}-${sizeIndex}`}>
                      <Col>
                        <Row className="align-items-center">
                          <Col md={4} className="my-1">
                            {colorName}
                            {sizeName ? ` - ${sizeName}` : ""}
                          </Col>
                          <Col md={3}>
                            <UppyUploader
                              currentImage={imageUrl}
                              title="Variant Image"
                              onChange={(imageId: string) => {
                                onChangeProductVariant(
                                  "image",
                                  imageId,
                                  colorIndex,
                                  sizeIndex
                                );
                              }}
                            />
                          </Col>
                          <Col md={5} className="my-1">
                            <FormGroup>
                              <Label for={`sku-${colorIndex}-${sizeIndex}`}>
                                SKU
                              </Label>
                              <Input
                                id={`sku-${colorIndex}-${sizeIndex}`}
                                value={sku}
                                invalid={error && !sku}
                                onChange={(e) => {
                                  onChangeProductVariant(
                                    "sku",
                                    e.target.value,
                                    colorIndex,
                                    sizeIndex
                                  );
                                }}
                                placeholder="SKU"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  );
                }
              )}

              {productVariants.length <= 0 && (
                <p className="text-center">There is no variants!</p>
              )}
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default ProductVariants;
