import {
  Input,
  Button,
  Modal,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import { ModalDiscountProps } from "./types";
import { useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";

const ModalDiscount: React.FC<ModalDiscountProps> = ({
  isShow,
  onClose,
  onSubmit,
  isLoadingConfirm,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  return (
    <Modal className="modal-dialog-centered" isOpen={isShow} toggle={onClose}>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Set Discount
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <Label>Discount</Label>
            <Input
              {...replaceRefToInneRef(register("discount", { required: true }))}
              invalid={errors.hasOwnProperty("discount")}
            />
            {errors.hasOwnProperty("discount") && (
              <FormFeedback>Discount is required!</FormFeedback>
            )}
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="submit"
            className="rounded-pill"
            disabled={isLoadingConfirm}
          >
            {isLoadingConfirm ? "Loading..." : "Save"}
          </Button>

          <Button
            className="ml-auto rounded-pill"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalDiscount;
