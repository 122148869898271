import React, { useEffect, useState, useRef } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import Sidebar from "../components/Sidebar";
import routes from "../constants/routes";
import { useAppSelector } from "../redux/store";
import { IRoute } from "../interfaces/global";

const Admin: React.FC = () => {
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const location = useLocation();
  const mainContentRef = useRef(null);

  useEffect(() => {
    //document.documentElement.scrollTop = 0;
    //document.scrollingElement.scrollTop = 0;
    if (mainContentRef.current) {
      // mainContentRef.current.scrollTop = 0;
    }
  }, [location]);

  const getRoutes: any = (routes: IRoute[]) => {
    return routes.map((prop, key) => {
      if (prop.collapse && prop.views) {
        return getRoutes(prop.views);
      }

      if (prop.layout === "/admin") {
        return (
          <Route
            exact={true}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path: string) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e: any) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/logo-3-dark.png"),
          imgAlt: "Kreaa Logo",
        }}
      />

      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          // brandText={getBrandText(location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
};

const AdminOutter = () => {
  const { isLogin } = useAppSelector((state) => state.auth);

  if (!isLogin) {
    return <Redirect to="/auth/login" />;
  }

  return <Admin />;
};

export default AdminOutter;
