import React, { RefObject } from "react";
import { DropTargetMonitor } from "react-dnd";

export const ItemTypes = {
  ROWITEM: "rowItem",
};

export interface DragItem {
  index: number;
  id: string;
  type: string;
  derp: string; // seeing if this breaks anything
}

export interface RowItemProps {
  id: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  children: React.FC<{
    dragRef: RefObject<HTMLDivElement>;
    previewRef: RefObject<HTMLDivElement | HTMLTableRowElement>;
    handlerId: any;
    isDragging: boolean;
  }>;
  onDrop?: (item: DragItem, monitor: DropTargetMonitor) => void;
}
