import classnames from "classnames";
import useOnLogout from "../../hooks/useOnLogout";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useAppSelector } from "../../redux/store";

interface AdminNavbarProps {
  theme: any;
  sidenavOpen: any;
  toggleSidenav: any;
}

const AdminNavbar: React.FC<AdminNavbarProps> = ({
  theme,
  sidenavOpen,
  toggleSidenav,
}) => {
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  const [redirect, setRedirect] = useState(false);

  const { me } = useAppSelector((state) => state.user);

  const { onLogout } = useOnLogout(() => {
    setRedirect(true);
  });

  if (redirect) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <Navbar
      className={classnames(
        "navbar-top navbar-expand border-bottom",
        { "navbar-dark bg-black": theme === "dark" },
        { "navbar-light bg-secondary": theme === "light" }
      )}
    >
      <Container fluid>
        <Collapse navbar isOpen={true}>
          <Nav className="align-items-center ml-md-auto" navbar>
            <NavItem className="d-xl-none">
              <div
                className={classnames(
                  "pr-3 sidenav-toggler",
                  { active: sidenavOpen },
                  { "sidenav-toggler-dark": theme === "dark" }
                )}
                onClick={toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </NavItem>
            <NavItem className="d-sm-none">
              <NavLink onClick={openSearch}>
                <i className="ni ni-zoom-split-in" />
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="align-items-center ml-auto ml-md-0" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="nav-link pr-0" color="" tag="a">
                <Media className="align-items-center">
                  <i className="ni ni-circle-08" />
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {me?.email || "No Name"}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <Link
                  to="/admin/account"
                  role="menuitem"
                  className="dropdown-item"
                >
                  <i className="ni ni-single-02"></i>
                  <span>My profile</span>
                </Link>

                <DropdownItem divider />
                <DropdownItem onClick={onLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
