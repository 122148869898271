import React from "react";
import ReactPaginate from "react-paginate";
import { PaginationProps } from "reactstrap";

const Pagination: React.FC<PaginationProps> = ({
  totalRow,
  perPage,
  onPageChange,
  initialPage,
  forcePage,
}) => {
  return (
    <>
      <ReactPaginate
        containerClassName="justify-content-end mb-0 pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        previousLabel={
          <>
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </>
        }
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextLabel={
          <>
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </>
        }
        nextClassName="page-item"
        nextLinkClassName="page-link"
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        // renderOnZeroPageCount={null}
        onPageChange={onPageChange}
        initialPage={initialPage}
        forcePage={forcePage}
        pageCount={Math.ceil(totalRow / perPage)}
      />
    </>
  );
};

export default Pagination;
