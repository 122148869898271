import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Link } from "react-router-dom";
import usePagination from "../../hooks/usePagination";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { assignOrder, getAll } from "../../redux/reducers/order";
import ModalAssign from "./ModalAssign";
import { IFormAssignData } from "./types";
import { IOrderStatus } from "../../services/orderApi/types";

const perPage = 10;
const Order: React.FC<{ orderStatus?: IOrderStatus }> = ({
  orderStatus,
  ...props
}) => {
  const { pageNumber, goToPage } = usePagination({ props });

  const dispatch = useAppDispatch();

  const { me } = useAppSelector((state) => state.user);

  const { isLoadingDatas, datas } = useAppSelector((state) => state.order);

  useEffect(() => {
    dispatch(getAll({ role: me?.role || "admin", orderStatus }));
  }, [me]);

  const [modalAssign, setModalAssign] = useState({
    isShow: false,
    orderId: "",
  });

  const onShowModalAssign = (orderId: string) => {
    setModalAssign((localData) => ({
      ...localData,
      isShow: true,
      orderId,
    }));
  };

  const onAdminAssign = async (id: string, input: IFormAssignData) => {
    await dispatch(
      assignOrder({ id, input: { adminId: input.admin as string } })
    );
  };

  return (
    <>
      {me?.role === "super-admin" && (
        <ModalAssign
          onClose={() =>
            setModalAssign((localData) => ({
              ...localData,
              isShow: false,
              orderId: "",
            }))
          }
          onSubmit={onAdminAssign}
          {...modalAssign}
        />
      )}
      <SimpleHeader name="Order" rightElements={<></>} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">List Orders</h3>
                  </Col>
                </Row>
              </CardHeader>

              {isLoadingDatas && (
                <CardBody>
                  <p className="text-center">Loading...</p>
                </CardBody>
              )}

              {!isLoadingDatas && datas.length <= 0 && (
                <CardBody>
                  <p className="text-center">There is no data!</p>
                </CardBody>
              )}

              {!isLoadingDatas && datas.length >= 1 && (
                <>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    striped
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Invoice Number</th>
                        <th>Customer Name</th>
                        <th>Company Name</th>
                        <th>Country</th>
                        <th>Status</th>
                        <th>Admin Handled</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datas.map((data) => (
                        <tr key={`${data.id}`}>
                          <td>{data.invoice_number}</td>
                          <td>{data.customer_name}</td>
                          <td>{data.company_name}</td>
                          <td>{data.company_country}</td>
                          <td>{data.status}</td>
                          <td>
                            <h4>
                              {me?.role === "super-admin" ? (
                                <Badge
                                  color={data.admin_name ? "success" : "danger"}
                                  onClick={() => onShowModalAssign(data.id)}
                                >
                                  {data.admin_name || "-- not assigned -"}
                                </Badge>
                              ) : (
                                <Badge
                                  color={data.admin_name ? "success" : "danger"}
                                >
                                  {data.admin_name || "-- not assigned -"}
                                </Badge>
                              )}
                            </h4>
                          </td>
                          <td className="table-actions">
                            <div className="d-flex">
                              <Link
                                className="table-action"
                                to={`/admin/order/${data.id}`}
                                id={`tooltip-edit-${data.id}`}
                              >
                                <i className="fas fa-eye" />
                              </Link>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltip-edit-${data.id}`}
                              >
                                View Order
                              </UncontrolledTooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    {/*<Pagination
                      totalRow={data.getPresetDataSets?.paginatorInfo.total}
                      perPage={perPage}
                      initialPage={pageNumber - 1}
                      onPageChange={(data) => {
                        goToPage(data);
                      }}
                    />
                    */}
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Order;

export const OrderNew: React.FC = (props) => (
  <Order orderStatus="new" {...props} />
);
export const OrderNegotiation: React.FC = (props) => (
  <Order orderStatus="negotiation" {...props} />
);
export const OrderProcessed: React.FC = (props) => (
  <Order orderStatus="processed" {...props} />
);
export const OrderFinished: React.FC = (props) => (
  <Order orderStatus="finished" {...props} />
);
export const OrderCancelled: React.FC = (props) => (
  <Order orderStatus="cancelled" {...props} />
);
