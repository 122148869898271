import {
  Input,
  Button,
  Modal,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import { ModalProductProps } from "./types";
import { Controller, useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";

const ModalProduct: React.FC<ModalProductProps> = ({
  isShow,
  onClose,
  onSubmit,
  isLoadingConfirm,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues });

  const promiseOptions = (inputValue: string) =>
    new Promise<any[]>((resolve) => {});

  return (
    <Modal className="modal-dialog-centered" isOpen={isShow} toggle={onClose}>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Product
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <Label>Product</Label>
            <Controller
              control={control}
              name="product"
              render={({ field: { onChange, value } }) => (
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                  onChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>Color</Label>
            <Input type="select" {...replaceRefToInneRef(register("color"))}>
              <option>Color 1</option>
              <option>Color 2</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Size</Label>
            <Input type="select" {...replaceRefToInneRef(register("size"))}>
              <option>Size 1</option>
              <option>Size 2</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Quantity</Label>
            <Input
              type="number"
              {...replaceRefToInneRef(register("qty", { required: true }))}
              invalid={errors.hasOwnProperty("qty")}
            />
            {errors.hasOwnProperty("qty") && (
              <FormFeedback>Quanity is required!</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Discount</Label>
            <Input {...replaceRefToInneRef(register("discount"))} />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="submit"
            className="rounded-pill"
            disabled={isLoadingConfirm}
          >
            {isLoadingConfirm ? "Loading..." : "Save"}
          </Button>

          <Button
            className="ml-auto rounded-pill"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalProduct;
