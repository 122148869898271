import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Link } from "react-router-dom";
import useConfirmation from "../../hooks/useConfirmation";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import usePagination from "../../hooks/usePagination";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { destroy, getCategoryTree } from "../../redux/reducers/category";
import { getImage } from "../../helpers/global";
import useQueryParams from "../../hooks/useQueryParams";
import queryString from "query-string";

const Category: React.FC = (props: any) => {
  const { pageNumber, goToPage } = usePagination({ props });

  const queryParams = useQueryParams();

  const { location } = props;

  const { pathname } = location;

  const keyValue = queryParams.get("key");

  const dispatch = useAppDispatch();

  const { isLoadingDatas, paginatedData, isLoadingDestroy } = useAppSelector(
    (state) => state.category
  );

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  useEffect(() => {
    dispatch(getCategoryTree({ page: pageNumber, key: keyValue || undefined }));
  }, [pageNumber, dispatch, keyValue]);

  const categoryLists = useMemo(() => {
    return (paginatedData?.data || []).map((elm) => ({
      ...elm,
      image: elm.image ? getImage(elm.image.image) : "",
    }));
  }, [paginatedData]);

  const { showConfirmation, hideConfirmation } =
    useConfirmation(isLoadingDestroy);

  const onDelete = async (id: string) => {
    try {
      await dispatch(destroy(id));

      showNotificationAlert({
        title: "Success",
        message: "You have deleted the category successfully",
      });
      dispatch(getCategoryTree({ page: pageNumber }));
    } catch (err: any) {
      switch (err.message) {
        default:
          showNotificationAlert({
            type: "danger",
            title: "Failed",
            message: err.message,
          });
          break;
      }
    } finally {
      hideConfirmation();
    }
  };

  const [searchValue, setSearchValue] = useState(keyValue || "");

  const searchSubmit = (e: any) => {
    e.preventDefault();

    const url = queryString.stringifyUrl({
      url: pathname,
      query: {
        key: searchValue,
      },
    });

    props.history.push(url);
  };

  return (
    <>
      <SimpleHeader
        name="Category"
        rightElements={
          <>
            <Link
              to="/admin/category/add"
              className="btn btn-neutral btn-sm"
              color="default"
            >
              Add New Category
            </Link>
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="mb-0">List Categories</h3>
                  </Col>
                  <Col>
                    <form onSubmit={searchSubmit}>
                      <InputGroup>
                        <Input
                          placeholder="Search Category"
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <Button>
                          <i className="fa fa-search" />
                        </Button>
                      </InputGroup>
                    </form>
                  </Col>
                </Row>
              </CardHeader>

              {isLoadingDatas && (
                <CardBody>
                  <p className="text-center">Loading...</p>
                </CardBody>
              )}

              {!isLoadingDatas && categoryLists?.length <= 0 && (
                <CardBody>
                  <p className="text-center">There is no data!</p>
                </CardBody>
              )}

              {!isLoadingDatas && categoryLists?.length >= 1 && (
                <>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    striped
                  >
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "1px" }} />
                        <th>Category Name</th>
                        <th>Manage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryLists.map((data: any) => (
                        <tr key={`data-${data.id}`}>
                          <td>
                            {data.image ? (
                              <img
                                src={data.image}
                                style={{ maxWidth: "100px" }}
                                alt={data.name}
                              />
                            ) : null}
                          </td>
                          <td>{data.name}</td>
                          <td className="table-actions">
                            <div className="d-flex">
                              <Link
                                className="table-action"
                                to={`/admin/category/${data.id}/edit`}
                                id={`tooltip-edit-${data.id}`}
                              >
                                <i className="fas fa-user-edit" />
                              </Link>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltip-edit-${data.id}`}
                              >
                                Edit Category
                              </UncontrolledTooltip>

                              <button
                                className="table-action table-action-delete"
                                id={`tooltip-delete-${data.id}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  showConfirmation({
                                    title: "Are you sure want to delete?",
                                    onConfirm: () => onDelete(data.id),
                                  });
                                }}
                              >
                                <i className="fas fa-trash" />
                              </button>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltip-delete-${data.id}`}
                              >
                                Delete Category
                              </UncontrolledTooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <Pagination
                      totalRow={paginatedData?.total}
                      perPage={paginatedData?.per_page}
                      initialPage={pageNumber - 1}
                      onPageChange={(data: any) => {
                        goToPage(data);
                      }}
                    />
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Category;
