import { useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getAll } from "../../../redux/reducers/country";
import { ICountryData } from "../../../services/countryApi/types";

const useCallCountries = () => {
  const dispatch = useAppDispatch();

  const { datas: countries } = useAppSelector((state) => state.country);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  const getCountryByName: (countryName: string) => ICountryData | undefined =
    useCallback(
      (countryName) => {
        return countries.find((elm) => elm.nicename === countryName);
      },
      [countries]
    );

  return { getCountryByName };
};

export default useCallCountries;
