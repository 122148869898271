import { forwardRef } from "react";
import { Input, InputProps } from "reactstrap";
import { FormCountryProps } from "./types";
import { useAppSelector } from "../../../redux/store";

const FormCountry = forwardRef<HTMLInputElement, FormCountryProps & InputProps>(
  ({ invalid, value, feedbackElements, onChange, ...props }, ref) => {
    const { datas: countries } = useAppSelector((state) => state.country);

    return (
      <Input
        innerRef={ref}
        {...props}
        type="select"
        invalid={invalid}
        value={value ? countries.find((elm) => elm.nicename === value)?.id : ""}
        onChange={(e) => {
          const selectedCountry = countries.find(
            (elm) => elm.id.toString() === e.target.value
          );
          onChange(selectedCountry);
        }}
      >
        <option value="">Select Country</option>
        {countries.map((elm) => (
          <option value={elm.id} key={elm.id}>
            {elm.nicename}
          </option>
        ))}
      </Input>
    );
  }
);

export default FormCountry;
