import React from "react";
import { ToggleProps } from "./types";

const Toggle: React.FC<ToggleProps> = ({
  active = true,
  onChange,
  isLoading,
}) => {
  return (
    <>
      <label className="table-action d-inline-block custom-toggle custom-toggle--type-2">
        {isLoading && <span className="custom-toggle__loader">Loading...</span>}
        {!isLoading && (
          <>
            <input
              checked={active}
              type="checkbox"
              onChange={(e: any) => {
                onChange(e.target.checked);
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="Inactive"
              data-label-on="Active"
            />
          </>
        )}
      </label>
    </>
  );
};

export default Toggle;
