import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get as apiGet, save as apiSave } from "../../services/accountApi";
import { IAccountData, IAccountInput } from "../../services/accountApi/types";

export const get = createAsyncThunk("account/get", async () => {
  const data = await apiGet();
  return data;
});

export const save = createAsyncThunk(
  "account/save",
  async (data: IAccountInput) => {
    await apiSave(data);
    return true;
  }
);

interface AccountSliceState {
  isLoadingData?: boolean;
  data?: IAccountData;
  isLoadingSave?: boolean;
}

const initialState: AccountSliceState = {
  isLoadingData: false,
  data: undefined,
  isLoadingSave: undefined,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(get.pending, (state) => {
      state.isLoadingData = true;
    });

    builder.addCase(get.fulfilled, (state, action) => {
      // state.data = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(save.pending, (state) => {
      state.isLoadingSave = true;
    });

    builder.addCase(save.fulfilled, (state) => {
      state.isLoadingSave = false;
    });
  },
});

export default accountSlice.reducer;
