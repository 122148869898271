import axios from "axios";
import queryString from "query-string";
import { ISimpleQueryParams } from "../../interfaces/global";

export const getAll = async (query?: ISimpleQueryParams) => {
  const { data } = await axios.get(
    queryString.stringifyUrl({
      url: `${process.env.REACT_APP_API_ENPOINT}/admin/media`,
      query: {
        page: query?.page,
        key: query?.key,
      },
    })
  );

  return data;
};

export const destroy = async (id: string) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_ENPOINT}/admin/media/${id}`
  );
};
