import { FileInput, useUppy } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";
import Uppy from "@uppy/core";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { useEffect, useState } from "react";
import { UppyUploaderProps } from "./types";
import UploaderPreview from "./Preview";
import cookie from "js-cookie";

const UppyUploader: React.FC<UppyUploaderProps> = ({
  allowedFileTypes,
  onChange,
  title,
  currentImage,
}) => {
  const [preview, setPreview] = useState<string>();

  const [isLoading, setIsloading] = useState(false);

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: true,
      allowMultipleUploadBatches: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: allowedFileTypes
          ? allowedFileTypes
          : ["image/*", ".jpg", ".jpeg", ".png"],
      },
    })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 140,
        waitForThumbnailsBeforeUpload: false,
      })
      .use(XHRUpload, {
        limit: 1,
        method: "post",
        endpoint: `${process.env.REACT_APP_API_ENPOINT}/admin/upload-media`,
        formData: true,
        fieldName: "image",
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": cookie.get("XSRF-TOKEN") || "",
        },
      })
      .on("upload", function () {
        setIsloading(true);
      })
      .on("thumbnail:generated", (file: any, preview: any) => {
        setPreview(preview);
      });
  });

  uppy.on("complete", function (result) {
    const id = result.successful[0].id;
    const imageId = (result.successful[0].response?.body.data as any)
      .id as string;
    uppy.removeFile(id);
    onChange(imageId);
    setIsloading(false);
  });

  const onRemove = () => {
    setPreview("");
    onChange("");
  };

  useEffect(() => {
    if (currentImage) {
      setPreview(currentImage);
    }
  }, [currentImage]);

  return (
    <div className="uploader">
      {isLoading && <div className="uploader-loading">Loading...</div>}

      <div style={{ display: isLoading ? "none" : "block" }}>
        {preview && <UploaderPreview image={preview} onRemove={onRemove} />}

        {!preview && (
          <FileInput
            uppy={uppy}
            pretty
            inputName="image"
            locale={{
              strings: {
                chooseFiles: title ? title : "Choose File",
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UppyUploader;
