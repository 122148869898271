import React, { useState, useEffect } from "react";
import FormCustomer from "./FormCustomer";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Redirect, useParams } from "react-router";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { Link } from "react-router-dom";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { IFormCustomerData } from "./types";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getOne, update } from "../../redux/reducers/customer";
import useCallCountries from "../../components/forms/FormCountry/useCallCountries";

const EditCustomer: React.FC = () => {
  const { id }: { id: string } = useParams();

  const { getCountryByName } = useCallCountries();

  const { isLoadingData, isLoadingSave, data } = useAppSelector(
    (state) => state.customer
  );

  const dispatch = useAppDispatch();

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    dispatch(getOne(id));
  }, []);

  const onSubmit = async (data: IFormCustomerData) => {
    const {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      country,
      phoneCode,
      phoneNumber,
      companyName,
      companyCountry,
      companyPhoneCode,
      companyPhoneNumber,
      companyAddress,
      companyCity,
      companyState,
      companyZipCode,
      companyRecipientName,
      deliveryAddress,
      deliveryCountry,
      deliveryPhoneCode,
      deliveryPhoneNumber,
      deliveryCity,
      deliveryState,
      deliveryZipCode,
      deliveryRecipientName,
    } = data;

    try {
      await dispatch(
        update({
          id,
          input: {
            email,
            password: password as string,
            password_confirmation: confirmPassword as string,
            first_name: firstName,
            last_name: lastName,
            country,
            phone_code: phoneCode,
            phone_number: phoneNumber,
            company_name: companyName,
            company_address: companyAddress,
            company_country: companyCountry,
            company_phone_code: companyPhoneCode,
            company_phone_number: companyPhoneNumber,
            company_city: companyCity,
            company_state: companyState,
            company_zip_code: companyZipCode,
            company_receipent_name: companyRecipientName,
            delivery_address: deliveryAddress,
            delivery_country: deliveryCountry,
            delivery_phone_code: deliveryPhoneCode,
            delivery_phone_number: deliveryPhoneNumber,
            delivery_city: deliveryCity,
            delivery_state: deliveryState,
            delivery_zip_code: deliveryZipCode,
            delivery_receipent_name: deliveryRecipientName,
          },
        })
      ).unwrap();

      showNotificationAlert({
        title: "Success",
        message: "You have updated the customer successfully",
      });

      setIsRedirect(true);
    } catch (err: any) {
      showNotificationAlert({
        type: "danger",
        title: "Failed",
        message: err,
      });
    }
  };

  if (isRedirect) {
    return <Redirect to="/admin/customer" />;
  }

  return (
    <>
      <SimpleHeader
        name="Edit Customer"
        parents={[{ to: "/admin/customer", label: "Customer" }]}
        rightElements={
          <Link
            to="/admin/customer"
            className="btn btn-neutral btn-sm"
            color="default"
          >
            Back
          </Link>
        }
      />
      {isLoadingData && (
        <Container className="mt--6" fluid>
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Customer Details</h3>
              </CardHeader>
              <CardBody>Loading...</CardBody>
            </Card>
          </div>
        </Container>
      )}
      {!isLoadingData && data && (
        <FormCustomer
          onSubmit={onSubmit}
          isLoadingSave={isLoadingSave}
          defaultValues={{
            email: data.email,
            country: "",
            phoneCode: "",
            phoneNumber: data.phone_number,
            firstName: data.first_name,
            lastName: data.last_name,
            companyName: data.company_name,
            companyCountry: data.company_country,
            companyPhoneCode:
              getCountryByName(data.company_country)?.phonecode || "",
            companyPhoneNumber: data.company_phone_number,
            companyAddress: data.company_address,
            companyCity: data.company_city,
            companyState: data.company_state,
            companyZipCode: data.company_zip_code,
            companyRecipientName: data.company_receipent_name,
            deliveryAddress: data.delivery_address,
            deliveryCountry: data.delivery_country,
            deliveryPhoneCode:
              getCountryByName(data.delivery_country)?.phonecode || "",
            deliveryPhoneNumber: data.delivery_phone_number,
            deliveryCity: data.delivery_city,
            deliveryState: data.delivery_state,
            deliveryZipCode: data.delivery_zip_code,
            deliveryRecipientName: data.delivery_receipent_name,
          }}
        />
      )}
    </>
  );
};

export default EditCustomer;
