import { DragDrop as UppyDragDrop, useUppy, ProgressBar } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";
import Uppy from "@uppy/core";
import { useState } from "react";
import { IFile, MultipleUploaderProps } from "../types";
import { v4 as uuidv4 } from "uuid";
import { getImage } from "../../../helpers/global";
import { IImage } from "../../../services/types";
import DragDropPreview from "./DragDropPreview";
import cookie from "js-cookie";

const DragDrop: React.FC<MultipleUploaderProps> = ({
  allowedFileTypes,
  onChange,
  values,
}) => {
  const [files, setFiles] = useState<IFile[]>(values || []);

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 10,
        allowedFileTypes: allowedFileTypes
          ? allowedFileTypes
          : ["image/*", ".jpg", ".jpeg", ".png"],
      },
      onBeforeFileAdded: (currentFile, files) => {
        const newFile = {
          ...currentFile,
          meta: { ...currentFile.meta, name: uuidv4() },
          name: uuidv4(),
        };
        console.log("__dataFile", currentFile, newFile);
        // if (currentFile.name === "forest-IMG_0616.jpg") {
        return newFile;
        // }
      },
    }).use(XHRUpload, {
      limit: 1,
      method: "post",
      endpoint: `${process.env.REACT_APP_API_ENPOINT}/admin/upload-media`,
      formData: true,
      fieldName: "image",
      withCredentials: true,
      headers: {
        "X-XSRF-TOKEN": cookie.get("XSRF-TOKEN") || "",
      },
    });
  });

  uppy.on("upload-success", function (file, response) {
    const data = response.body?.data as IImage;
    addFile({
      id: uuidv4(),
      url: getImage(data.image),
      fileId: data.id,
    });
    uppy.removeFile(file?.id as string);
  });

  uppy.on("complete", function () {
    console.log("completessss");
  });

  uppy.on("upload-error", function () {
    console.log("__test12334");
  });

  const addFile = (file: IFile) => {
    const newFiles = [...files];
    newFiles.push(file);
    setFiles(newFiles);
    onChange(newFiles);
  };

  return (
    <div className="uploader">
      <UppyDragDrop
        width="100%"
        height="100%"
        // note="Images up to 200×200px"
        uppy={uppy}
        locale={{
          strings: {
            dropHereOr: "Drop here or %{browse}",
            browse: "browse",
          },
        }}
      />

      <div className="mt-2">
        <ProgressBar
          uppy={uppy}
          // fixed
          hideAfterFinish
        />
      </div>

      {files.length >= 1 && <DragDropPreview files={files} />}
    </div>
  );
};

export default DragDrop;
