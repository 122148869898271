import {
  Input,
  Button,
  Modal,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import { useEffect } from "react";
import { ModalQtyProps } from "./types";
import { useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";

const ModalQty: React.FC<ModalQtyProps> = ({
  isShow,
  onClose,
  onSubmit,
  isLoadingConfirm,
  orderItemId,
  qty,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("qty", qty || "");
  }, [qty]);

  return (
    <Modal className="modal-dialog-centered" isOpen={isShow} toggle={onClose}>
      <form
        onSubmit={handleSubmit((data) => {
          if (onSubmit && orderItemId) {
            onSubmit(orderItemId, data);
          }
        })}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Update Qty
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <Label>Qty</Label>
            <Input
              {...replaceRefToInneRef(register("qty", { required: true }))}
              invalid={errors.hasOwnProperty("qty")}
            />
            {errors.hasOwnProperty("qty") && (
              <FormFeedback>Qty is required!</FormFeedback>
            )}
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="submit"
            className="rounded-pill"
            disabled={isLoadingConfirm}
          >
            {isLoadingConfirm ? "Loading..." : "Save"}
          </Button>

          <Button
            className="ml-auto rounded-pill"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalQty;
