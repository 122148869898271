import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAll as apiGetAll } from "../../services/countryApi";
import { ICountryData } from "../../services/countryApi/types";

export const getAll = createAsyncThunk("countries/getAll", async () => {
  const data = await apiGetAll();
  return data;
});

interface CountrySliceState {
  isLoadingDatas: boolean;
  datas: ICountryData[];
}

const initialState: CountrySliceState = {
  isLoadingDatas: false,
  datas: [],
};

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.isLoadingDatas = true;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.datas = action.payload;
      state.isLoadingDatas = false;
    });

    builder.addCase(getAll.rejected, (state, action) => {
      state.isLoadingDatas = false;
    });
  },
});

export default countrySlice.reducer;
