import React, { useState, useEffect } from "react";
import FormCategory from "./FormCategory";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Redirect, useParams } from "react-router";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { Link } from "react-router-dom";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { IFormCategoryData } from "./types";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getOne, update } from "../../redux/reducers/category";
import { getImage } from "../../helpers/global";

const EditCategory: React.FC = () => {
  const { id }: { id: string } = useParams();

  const { isLoadingData, isLoadingSave, data } = useAppSelector(
    (state) => state.category
  );

  const dispatch = useAppDispatch();

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    dispatch(getOne(id));
  }, [dispatch, id]);

  const onSubmit = async (data: IFormCategoryData) => {
    const { name, slug, description, parent_id, image } = data;
    try {
      await dispatch(
        update({
          id,
          input: {
            name,
            slug,
            description,
            parent_id,
            image: image?.toString(),
          },
        })
      ).unwrap();

      showNotificationAlert({
        title: "Success",
        message: "You have updated the category successfully",
      });

      setIsRedirect(true);
    } catch (err: any) {
      showNotificationAlert({
        type: "danger",
        title: "Failed",
        message: err,
      });
    }
  };

  if (isRedirect) {
    return <Redirect to="/admin/category" />;
  }

  return (
    <>
      <SimpleHeader
        name="Edit Category"
        parents={[{ to: "/admin/category", label: "Category" }]}
        rightElements={
          <Link
            to="/admin/category"
            className="btn btn-neutral btn-sm"
            color="default"
          >
            Back
          </Link>
        }
      />
      {isLoadingData && (
        <Container className="mt--6" fluid>
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Category Details</h3>
              </CardHeader>
              <CardBody>Loading...</CardBody>
            </Card>
          </div>
        </Container>
      )}
      {!isLoadingData && data && (
        <FormCategory
          onSubmit={onSubmit}
          isLoadingSave={isLoadingSave}
          defaultValues={{
            name: data.name,
            slug: data.slug,
            description: data.description,
            parent_id: data.parent_id,
            image: data.image?.id,
          }}
          imagePreview={data.image ? getImage(data.image.image) : ""}
          type="edit"
        />
      )}
    </>
  );
};

export default EditCategory;
