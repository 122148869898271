// Contain at least 1 lowercase, 1 uppercase, 1 special character, and 1 number.
export const passwordPattern =
  /^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()\-_+={}[\]|\\:;"'<>,.?]).*$/;

export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const slugPattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

export const statusFromBEMap = {
  active: "Active",
  deactive: "Deactive",
};
