import React, { useState } from "react";
import FormUser from "./FormUser";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { insert } from "../../redux/reducers/user";
import { IFormUser } from "./types";

const AddUser: React.FC = () => {
  const [isRedirect, setIsRedirect] = useState(false);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  const dispatch = useAppDispatch();

  const { isLoadingSave } = useAppSelector((state) => state.user);

  const onSubmit = async (data: IFormUser) => {
    const { firstName, lastName, email, password, confirmPassword, role } =
      data;

    try {
      await dispatch(
        insert({
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password as string,
          password_confirmation: confirmPassword as string,
          role: "admin",
          status: "active",
        })
      ).unwrap();

      showNotificationAlert({
        title: "Success",
        message: "You have added a user successfully",
      });

      setIsRedirect(true);
    } catch (err: any) {
      showNotificationAlert({
        type: "danger",
        title: "Failed",
        message: err,
      });
    }
  };

  if (isRedirect) {
    return <Redirect to="/admin/user" />;
  }

  return (
    <>
      <SimpleHeader
        name="Add User"
        parents={[{ to: "/admin/user", label: "User" }]}
        rightElements={
          <Link
            to="/admin/user"
            className="btn btn-neutral btn-sm"
            color="default"
          >
            Back
          </Link>
        }
      />
      <FormUser onSubmit={onSubmit} isLoadingSave={isLoadingSave} />
    </>
  );
};

export default AddUser;
