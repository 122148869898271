import React, { useRef, useEffect, useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import AuthFooter from "../components/Footers/AuthFooter";
import routes from "../constants/routes";
import { useAppSelector } from "../redux/store";

function Auth() {
  const location = useLocation();
  const mainContentRef = useRef(null);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-default");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  });

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // mainContentRef.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/auth/login" />
        </Switch>
      </div>
      <AuthFooter />
    </>
  );
}

const AuthOutter = () => {
  const [isMounted, setIsMounted] = useState(false);
  const { isLogin } = useAppSelector((state) => state.auth);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  if (isLogin && !isMounted) {
    return <Redirect to="/admin/dashboard" />;
  }

  return <Auth />;
};

export default AuthOutter;
