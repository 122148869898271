import React from "react";
import { Button, Modal } from "reactstrap";
import { ConfirmationProps } from "./types";

const ModalConfirmation: React.FC<ConfirmationProps> = ({
  onConfirm = () => {
    console.log("Confirm!");
  },
  onCancel = () => {
    console.log("Cancel!");
  },
  title = "Are you sure?",
  description = "Are you sure?",
  isLoadingConfirm = false,
  isShow = false,
  confirmTitle = "",
  cancelTitle = "",
}) => {
  return (
    <Modal className="modal-dialog-centered" isOpen={isShow} toggle={onCancel}>
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {title}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onCancel}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <p>{description}</p>
      </div>
      <div className="modal-footer">
        <Button
          type="button"
          color="info"
          onClick={() => {
            if (onConfirm !== undefined) {
              onConfirm();
            }
          }}
          disabled={isLoadingConfirm}
        >
          {isLoadingConfirm ? "Loading..." : confirmTitle || "Sure!"}
        </Button>

        <Button
          className="ml-auto"
          data-dismiss="modal"
          type="button"
          color="link"
          onClick={() => {
            if (onCancel !== undefined) {
              onCancel();
            }
          }}
        >
          {cancelTitle || "Cancel"}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
