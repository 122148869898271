import { Label, FormGroup, Input, Col, Button, FormFeedback } from "reactstrap";
import { useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";
import FormPassword from "../../components/forms/FormPassword";
import { passwordPattern } from "../../constants/global";
import { useState } from "react";
import UpdateEmailModal from "./UpdateEmailModal";
import { AccountFormProps, IFormAccount } from "./types";

const AccountForm: React.FC<AccountFormProps> = ({
  onSubmit,
  defaultValues,
  isLoadingSave,
  email,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues,
  });

  const localSubmit = (data: IFormAccount) => {
    onSubmit(data, () => {
      setValue("password", "");
      setValue("confirmPassword", "");
    });
  };

  const [isModalEmailShow, setIsModalEmailShow] = useState(false);

  return (
    <>
      <UpdateEmailModal
        isOpen={isModalEmailShow}
        onClose={() => setIsModalEmailShow(false)}
      />
      <form onSubmit={handleSubmit(localSubmit)}>
        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="first-name" md="3">
            First Name
          </Label>
          <Col md="9">
            <Input
              id="first-name"
              type="text"
              {...replaceRefToInneRef(
                register("firstName", { required: true })
              )}
              invalid={errors.hasOwnProperty("firstName")}
            />
            {errors.hasOwnProperty("firstName") && (
              <FormFeedback>First Name is required!</FormFeedback>
            )}
          </Col>
        </FormGroup>

        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="last-name" md="3">
            Last Name
          </Label>
          <Col md="9">
            <Input
              id="last-name"
              type="text"
              {...replaceRefToInneRef(register("lastName", { required: true }))}
              invalid={errors.hasOwnProperty("lastName")}
            />
            {errors.hasOwnProperty("lastName") && (
              <FormFeedback>Last Name is required!</FormFeedback>
            )}
          </Col>
        </FormGroup>

        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="email" md="3">
            Email
          </Label>
          <Col md="9">
            <Input id="email" type="text" readOnly value={email} />
            {/*<Button
              color="link"
              className="px-0"
              onClick={() => setIsModalEmailShow(true)}
            >
              Change Email
            </Button>*/}
          </Col>
        </FormGroup>

        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="password" md="3">
            Change Password
          </Label>
          <Col md="9">
            <FormPassword
              {...register("password", {
                minLength: 8,
                pattern: passwordPattern,
              })}
              // placeholder="Password"
              // id="password"
              invalid={errors.hasOwnProperty("password")}
              feedbackElements={
                <>
                  {errors?.password?.type === "minLength" && (
                    <FormFeedback>
                      The minimum password length is 8!
                    </FormFeedback>
                  )}
                  {errors?.password?.type === "pattern" && (
                    <FormFeedback>
                      The password should contain at least 1 lowercase, 1
                      uppercase, 1 special character, and 1 number.
                    </FormFeedback>
                  )}
                </>
              }
            />
          </Col>
        </FormGroup>

        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="confirm-password"
            md="3"
          >
            Confirm Password
          </Label>
          <Col md="9">
            <FormPassword
              {...register("confirmPassword", {
                minLength: 8,
                pattern: passwordPattern,
                validate: (val) => {
                  if (watch("password") !== val) {
                    return "The password fields do not match";
                  }
                },
              })}
              // placeholder="Confirm Password"
              // id="confirm-password"
              invalid={errors.hasOwnProperty("confirmPassword")}
              feedbackElements={
                <>
                  {errors?.confirmPassword?.type === "minLength" && (
                    <FormFeedback>
                      The minimum password length is 8!
                    </FormFeedback>
                  )}
                  {errors?.confirmPassword?.type === "pattern" && (
                    <FormFeedback>
                      The password should contain at least 1 lowercase, 1
                      uppercase, 1 special character, and 1 number.
                    </FormFeedback>
                  )}
                  {errors?.confirmPassword?.type === "validate" && (
                    <FormFeedback>
                      <>{errors.confirmPassword.message}</>
                    </FormFeedback>
                  )}
                </>
              }
            />
          </Col>
        </FormGroup>

        <Button color="primary" disabled={isLoadingSave}>
          {isLoadingSave && <span>Loading...</span>}
          {!isLoadingSave && <span>Save</span>}
        </Button>
      </form>
    </>
  );
};

export default AccountForm;
