import AppContext from "../contexts/AppContext";
import { useContext } from "react";

interface IUseNotificationAlert {
  title?: string;
  message?: string;
  place?: "tc" | "tl" | "tr" | "bl" | "bc" | "br";
  type?:
    | "info"
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "light"
    | "dark";
}

const useNotificationAlert = ({
  title: defaultTitle,
  message: defaultMessage,
  place: defaultPlace,
  type: defaultType,
}: IUseNotificationAlert) => {
  const { notificationAlertRef } = useContext(AppContext);

  const show = (localOptions: IUseNotificationAlert) => {
    const title = localOptions?.title || defaultTitle;
    const message = localOptions?.message || defaultMessage;
    const place = localOptions?.place || defaultPlace || "tc";
    const type = localOptions?.type || defaultType || "info";

    const configuration = {
      place,
      message: message as string,
      /*
      message: (
        <div className="alert-text">
          {title && (
            <span className="alert-title" data-notify="title">
              {title}
            </span>
          )}
          {message && <span data-notify="message">{message}</span>}
        </div>
      ),
      */
      type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };

    notificationAlertRef?.current?.notificationAlert(configuration);
  };

  return {
    show,
  };
};

export default useNotificationAlert;
