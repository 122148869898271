import React, { useState } from "react";
import FormCategory from "./FormCategory";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import useNotificationAlert from "../../hooks/useNotificationAlert";
import { IFormCategoryData } from "./types";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { insert } from "../../redux/reducers/category";

const AddCategory: React.FC = () => {
  const [isRedirect, setIsRedirect] = useState(false);

  const dispatch = useAppDispatch();

  const { isLoadingSave } = useAppSelector((state) => state.category);

  const { show: showNotificationAlert } = useNotificationAlert({
    place: "br",
  });

  const onSubmit = async (data: IFormCategoryData) => {
    const { name, slug, description, parent_id, image } = data;

    try {
      await dispatch(
        insert({ name, slug, description, parent_id, image: image?.toString() })
      ).unwrap();

      showNotificationAlert({
        title: "Success",
        message: "You have added a category successfully",
      });

      setIsRedirect(true);
    } catch (err: any) {
      showNotificationAlert({
        type: "danger",
        title: "Failed",
        message: err,
      });
    }
  };

  if (isRedirect) {
    return <Redirect to="/admin/category" />;
  }

  return (
    <>
      <SimpleHeader
        name="Add Category"
        parents={[{ to: "/admin/category", label: "Category" }]}
        rightElements={
          <Link
            to="/admin/category"
            className="btn btn-neutral btn-sm"
            color="default"
          >
            Back
          </Link>
        }
      />
      <FormCategory
        onSubmit={onSubmit}
        isLoadingSave={isLoadingSave}
        type="add"
      />
    </>
  );
};

export default AddCategory;
