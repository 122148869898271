import {
  Input,
  Button,
  Modal,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import { useEffect, useState } from "react";
import { ModalAssignProps } from "./types";
import { useForm } from "react-hook-form";
import { replaceRefToInneRef } from "../../helpers/global";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getAll } from "../../redux/reducers/admin";

const ModalAssign: React.FC<ModalAssignProps> = ({
  isShow,
  onClose,
  onSubmit,
  isLoadingConfirm,
  orderId,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  /*
  useEffect(() => {
    setValue("status", customerData?.status);
  }, [customerData]);
  */

  const dispatch = useAppDispatch();

  const { datas: admins } = useAppSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getAll());
  }, []);

  return (
    <Modal className="modal-dialog-centered" isOpen={isShow} toggle={onClose}>
      <form onSubmit={handleSubmit((data) => onSubmit(orderId, data))}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Assign to Admin
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <Label>Admin</Label>
            <Input
              type="select"
              {...replaceRefToInneRef(register("admin", { required: true }))}
              invalid={errors.hasOwnProperty("admin")}
            >
              <option value="">Select Admin</option>
              {admins.map((admin) => (
                <option value={admin.id} key={admin.id}>
                  {`${admin.first_name} ${admin.last_name}`}
                </option>
              ))}
            </Input>
            {errors.hasOwnProperty("admin") && (
              <FormFeedback>Admin is required!</FormFeedback>
            )}
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="submit"
            className="rounded-pill"
            disabled={isLoadingConfirm}
          >
            {isLoadingConfirm ? "Loading..." : "Save"}
          </Button>

          <Button
            className="ml-auto rounded-pill"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalAssign;
